<!-- 工艺备注 -->
<template>
  <BaseFormItem
    :label="fields.label"
  >
    <BaseInput
      v-model="val"
      type="textarea"
      width="500px"
      resize="none"
      placeholder="如有在极性方向、元器件要求、回流温度、特殊开网不上锡等方面的要求请备注"
      :row="3"
      @input="handleEmit"
    />
  </BaseFormItem>
</template>

<script>
import { getSMTFields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getSMTFields('postscript')
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
    }
  }
}
</script>

<style lang="scss">
</style>