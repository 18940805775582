<!-- 拼版款数 示例 -->
<template>
  <el-popover 
    popper-class="pbnum-example"
    placement="right" 
    width="387" 
    trigger="hover"
  >
    <div class="pbnum-example__content">
      <div v-for="item in list" :key="item.text">
        <img :src="item.img" />
        <p>{{ item.text }}</p>
      </div>
    </div>
    <BaseTextButton slot="reference">示例</BaseTextButton>
  </el-popover>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { text: '同款拼板，拼版出货（1款）', img: require('@@/assets/img/pcb/pbnum_1.png') },
        { text: '不同款拼板，拼版出货（4款）', img: require('@@/assets/img/pcb/pbnum_4.png') },
        { text: '不同款拼板，拼版出货（5款）', img: require('@@/assets/img/pcb/pbnum_5.png') }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.pbnum-example {
  box-sizing: border-box;
  .pbnum-example__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 8px 13px;
    >div {
      width: 145px;
      &:last-child {
        margin-top: 20px;
      }
      >img {
        display: block;
        width: 100%;
      }
      >p {
        margin-top: 15px;
        white-space: nowrap;
        font-size: 12px;
        color: $--color-text-secondary;
      }
    }
  }
}
</style>