<!-- 生产数量 -->
<template>
  <BaseFormItem
    :unit="fields.unit"
    :label="fields.label"
    :labelTips="fields.labelTips"
    isShowRequiredIcon
    :fillTips="fillTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseInput
      v-model="val"
      width="200px"
      placeholder="生产数量"
      :max="max"
      @input="val => $emit('input', val)"
      @change="handleChange"
    ></BaseInput>
  </BaseFormItem>
</template>

<script>
import { getSMTFields } from '@@/utils/getFields'
import { isPositiveInteger } from '@@/utils/utils'

export default {
  props: {
    value: {
      type: [Number, String],
      default: 1
    },
    max: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      val: this.value,
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getSMTFields('number')
    },
    // 是否显示未填写样式
    fillTips() {
      const val = this.val
      return !val ? '请输入' : ''
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  },
  methods: {
    handleChange(val) {
      if (isPositiveInteger(val)) {
        // 最小值 1
        val = Math.max(val, 1)
        // 最大值 1000000
        val = Math.min(val, 1000000)
        // 四舍五入
        val = Math.round(val)
        this.val = val
        this.$emit('input', val)
        this.$emit('change', val)
        this.isShowError(false)
      } else {
        this.$emit('input', '')
        this.$emit('change', '')
      }
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
</style>