<!-- 快递公司 -->
<template>
  <el-popover
    class="express-select"
    width="360"
    placement="bottom-start"
    v-model="isShow"
  >
    <!-- 快递选项 -->
    <ExpressList
      v-model="val" 
      v-bind="$attrs"
      @change="handleChange"
    />
    <div
      slot="reference"
      class="express-select__reference"
      :class="{ 'is-focus': isShow, 'has-value': !!currentItem.shipping_id }"
    >
      <p v-if="currentItem.shipping_id">
        <span>{{ currentItem.shipping_name }}</span>
      </p>
      <p v-else class="not-value">请选择</p>
      <i class="el-icon-circle-close express-select__close" @click="handleClear" />
      <i class="el-icon-arrow-up express-select__arrow" />
    </div>
  </el-popover>
</template>

<script>
import ExpressList from './list.vue'

import { getFR4Fields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    }
  },
  components: {
    ExpressList
  },
  data() {
    return {
      isShow: false,
      val: this.value,
      currentItem: {},
      expressListRef: null
    }
  },
  computed: {
    fields() {
      return getFR4Fields('express')
    }
  },
  watch: {
    value(newVal) {
      this.val = +newVal
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
    },
    handleChange(item) {
      this.isShow = false
      this.currentItem = item || {}
      this.handleEmit()
      this.$emit('change', item)
    },
    handleClear(event) {
      event.stopPropagation()
      this.val = ''
      this.handleChange({})
    },
    getList() {
      this.expressListRef.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.express-select {
  .express-select__reference {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px 0 $--input-padding-left;
    width: 100%;
    height: 32px;
    border: 1px solid $--radio-border-color;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    &:hover {
      border-color: #aaa;
    }
    &.is-focus {
      border-color: $--form-color-base;
      .express-select__arrow {
        transform: rotateZ(0);
      }
    }
    &.has-value:hover {
      .express-select__arrow {
        display: none;
      }
      .express-select__close {
        display: inline-block;
      }
    }
    .express-select__close,
    .express-select__arrow {
      font-size: 14px;
      color: #C0C4CC;
      transform: rotateZ(180deg);
      transition: all .3s;
    }
    .express-select__close {
      display: none;
    }
  }
  .not-value {
    color: #c0c4cc;
  }
}
</style>