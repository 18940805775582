<!-- 总费用明细 -->
<template>
  <div class="price-total-view">
    <div class="price-view">
      <ul>
        <li v-for="(item, index) in priceTotalFields">
          <span class="price-view__label">
            {{ item.label }}：
          </span>
          <span 
            :class="{ 
              'price-view__value': true, 
              'has-tips': item.tips,
            }"
            :style="{ color: item.color }"
          >
            <BaseTipPopover v-if="item.tips" :content="item.tips" />
            <div 
              v-if="item.label === '优惠券'" 
              class="price-view__coupon"
              :class="{ 'not-usable': !item.value }"
              @click="handleOpenCouponDialog"
            >
              <template v-if="item.value">
                最高可优惠<span>{{ item.value }}</span>元
              </template>
              <template v-else>
                无可用优惠券
              </template>
              <i class="el-icon-arrow-right"></i>
            </div>
            <template v-else>
              {{ `${item.symbol || ''}${item.hideUnit ? '' : '￥'}${item.value}` }}
            </template>
          </span>
        </li>
      </ul>
      <!-- 阶梯价提示 -->
      <p v-if="data.ladderPriceTips" class="price-view__ladderPriceTips">
        <span>
          <i></i>
          {{ data.ladderPriceTips }}
        </span>
      </p>
    </div>
    <!-- PCB优惠券弹框 -->
    <PcbCouponDialog ref="PcbCouponDialogRef" />
  </div>
</template>

<script>
// PCB优惠券弹框
import PcbCouponDialog from '@@/components/pcbCoupon/index'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      validator: function (value) {
        return ['PCB', 'FPC', 'SMT', 'PCB_SMT', 'FPC_SMT', 'SMT_PCB', 'SMT_FPC'].includes(value)
      }
    },
    isFpcNeedAudit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PcbCouponDialog
  },
  computed: {
    showFields() {
      const type = this.type
      return {
        pcb_amount: { label: 'PCB制作费', show: type !== 'SMT' },
        smt_amount: { label: 'SMT加工费', show: type.includes('SMT') },
        insurance_amount: { label: 'PCB品质服务费', show: false },
        freight_amount: { label: '运费', show: true },
        tax_amount: { label: '税费', show: true },
        discount_amount: { label: '活动折扣', show: true },
        bonus_activity: { label: '优惠券', show: false },
      }
    },
    // 是否显示优惠券
    isShowBonus() {
      const { total_amount, detail } = this.data
      if (
        total_amount > 0 && 
        detail && 
        detail.pcb && 
        detail.pcb.bonus_activity
      ) {
        // 预估总价 > 0 且 优惠券有数据
        const { usable_coupon, not_usable_coupon } = detail.pcb.bonus_activity
        return  [...(usable_coupon || []), ...(not_usable_coupon || [])].length > 0
      }
      return false
    },
    priceTotalFields() {
      const { data, showFields, isFpcNeedAudit, type, isShowBonus } = this
      // 活动提示
      const { pcb = [], smt = [] } = data.activity || {}
      const tips = [...pcb, ...smt].map(item => `${item.title}：${item.amount}`).join(`\n`)

      if (isFpcNeedAudit && type === 'FPC') {
        return ['pcb_amount', 'freight_amount', 'tax_amount']
          .map(key => ({ label: showFields[key].label, value: '----', hideUnit: true }))
      }
      
      return Object.keys(showFields)
        .filter(key => showFields[key].show || (data[key] && data[key] > 0) || (key === 'bonus_activity' && isShowBonus))
        .map(key => {
          const label = showFields[key].label
          // PCB制作费
          if (key === 'pcb_amount' && isFpcNeedAudit && ['FPC_SMT', 'SMT_FPC'].includes(type)) {
            return { label, value: '以审核为准', hideUnit: true, color: '#E6A23C' }
          }
          // 活动折扣
          if (key === 'discount_amount') {
            return { label, value:  (+data[key] || 0).toFixed(2), symbol: '-', tips, color: '#F65001' }
          }
          // PCB优惠券
          if (key === 'bonus_activity') {
            const optimum_coupon = (data.detail.pcb.bonus_activity || {}).optimum_coupon || {}
            return { label, value: optimum_coupon.money }
          }
          return { label, value:  (+data[key] || 0).toFixed(2) }
        })
    }
  },
  methods: {
    handleOpenCouponDialog() {
      this.$refs.PcbCouponDialogRef.open(this.data.detail.pcb.bonus_activity)
    }
  }
}
</script>

<style lang="scss" scoped>
.price-total-view {
  margin-top: 12px;
}
.price-view {
  padding: 12px;
  border: 1px dashed $--border-color-base;
  border-radius: $--border-radius-base;
  >ul {
    line-height: 24px;
    text-align: right;
    >li {
      display: flex;
      justify-content: flex-end;
    }
  }
  .price-view__value {
    display: block;
    width: 140px;
    &.has-tips {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .price-view__coupon {
    display: inline-block;
    padding: 0 3px 0 8px;
    line-height: 16px;
    font-size: 12px;
    color: #AD8C6D;
    border-radius: 9px;
    border: 1px solid #F4DED4;
    background-color: #FFFCF5;
    cursor: pointer;
    &:hover {
      border-color: #F8D4C4;
      background-color: #FEF4DF;
    }
    >span {
      color: #CC0000;
    }
    &.not-usable {
      color: #8F939B;
      border-color: #E0E4EC;
      background-color: #FBFCFF;
      &:hover {
        border-color: #D5D8DF;
        background-color: #F1F4FF;
      }
    }
  }
  .price-view__ladderPriceTips {
    margin-top: 10px;
    text-align: right;
    >span {
      >i {
        margin-right: 4px;
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: bottom;
        background: url(~@@/assets/img/icons/icon_minus.png);
      }
      padding: 2px 6px;
      font-size: 12px;
      border-radius: 4px 0px 0px 0px;
      background: rgba($color: #F65001, $alpha: 0.08);
    }
  }
}
</style>