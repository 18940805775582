<!-- X-Ray -->
<template>
  <BaseFormItem
    class="x-ray"
    :label="fields.label"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <!-- 检测单片数 -->
    <BaseInput 
      class="x-ray__input"
      v-model="val.x_ray_unit_number" 
      width="166px"
      placeholder="请输入"
      @input="val => handleEmit('x_ray_unit_number')"
      @change="val => handleChange(val, 'x_ray_unit_number')"
    >
      <div slot="prepend">
        检测单片数
        <BaseTipPopover content="需要X-Ray检测的单板数量" />
      </div>
    </BaseInput>
    <!-- 单片元件数 -->
    <BaseInput 
      class="x-ray__input"
      v-model="val.x_ray_number" 
      width="166px"
      placeholder="请输入"
      @input="val => handleEmit('x_ray_number')"
      @change="val => handleChange(val, 'x_ray_number')"
    >
      <div slot="prepend">
        单片元件数
        <BaseTipPopover content="每片板子需要X-Ray检测的元器件数量" />
      </div>
    </BaseInput>
  </BaseFormItem>
</template>

<script>
import { getSMTFields } from '@@/utils/getFields'
import { isPositiveInteger } from '@@/utils/utils'

export default {
  props: {
    // X-Ray单板数
    x_ray_unit_number: {
      type: [Number, String],
      default: ''
    },
    // X-Ray元件数
    x_ray_number: {
      type: [Number, String],
      default: ''
    },
    // 生产数量
    number: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      val: {
        x_ray_unit_number: this.x_ray_unit_number,
        x_ray_number: this.x_ray_number
      },
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getSMTFields('x_ray')
    }
  },
  watch: {
    x_ray_unit_number(newVal) {
      this.val.x_ray_unit_number = newVal
    },
    x_ray_number(newVal) {
      this.val.x_ray_number = newVal
    }
  },
  methods: {
    handleEmit(key) {
      this.$emit(`update:${key}`, this.val[key])
      this.isShowError(false)
    },
    handleChange(val, key) {
      this.val[key] = isPositiveInteger(val) ? val : ''
      // 检测单片数最大值为生产数量
      if (key === 'x_ray_unit_number' && this.number) {
        this.val[key] = Math.min(val, this.number)
      }
      this.handleEmit(key)
      this.handleEmitChange()
    },
    handleEmitChange() {
      this.$emit('change', { ...this.val })
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
.x-ray {
  .x-ray__input {
    margin-right: 20px;
    & ::v-deep {
      .el-input-group__prepend {
        padding: 0 5px;
        >div {
          display: inline-block;
        }
      }
    }
  }
}
</style>