<!-- 制程要求 -->
<template>
  <BaseFormItem
    :label="fields.label"
    :labelTips="fields.labelTips"
    :fillTips="fillTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getSMTFields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      val: this.value,
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getSMTFields('solder_paste_type')
    },
    // 是否显示未填写样式
    fillTips() {
      const val = this.val
      return !val && val !== 0 ? '请选择' : ''
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
      this.isShowError(false)
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss">
</style>