<!-- 单/双面 -->
<template>
  <BaseFormItem
    class="patch"
    :label="fields.label"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <!-- 单/双面 -->
    <BaseRadioGroup 
      v-model="val.single_or_double_technique" 
      :disabled="!disabledTechnique"
      @change="() => handleEmit('single_or_double_technique')"
    >
      <el-radio-button
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
      >
        {{ item.label }}
      </el-radio-button>
    </BaseRadioGroup>
    <i class="el-icon-minus patch__separator" />
    <!-- BOM物料 -->
    <BaseInput 
      class="patch__input required"
      v-model="val.bom_material_type_number" 
      width="170px"
      placeholder="请输入"
      @input="val => handleEmit('bom_material_type_number')"
      @change="val => handleChange(val, 'bom_material_type_number')"
    >
      <div slot="prepend">
        BOM物料
        <BaseTipPopover content="BOM表中需要焊接&贴片的总物料种类" />
      </div>
      <template slot="append">种</template>
    </BaseInput>
  </BaseFormItem>
</template>

<script>
import { getSMTFields } from '@@/utils/getFields'
import { isPositiveInteger } from '@@/utils/utils'

export default {
  props: {
    // 单/双面
    single_or_double_technique: {
      type: Number,
      default: 1
    },
    // BOM物料
    bom_material_type_number: {
      type: [Number, String],
      default: ''
    },
    // 是否能修改贴装面
    disabledTechnique: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      val: {
        single_or_double_technique: this.single_or_double_technique,
        bom_material_type_number: this.bom_material_type_number,
      },
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getSMTFields('patch')
    }
  },
  watch: {
    single_or_double_technique(newVal) {
      this.val.single_or_double_technique = newVal
    },
    bom_material_type_number(newVal) {
      this.val.bom_material_type_number = newVal
      if (newVal) {
        this.isShowError(false)
      }
    },
  },
  methods: {
    handleEmit(key) {
      this.$emit(`update:${key}`, this.val[key])
      this.isShowError(false)
    },
    handleChange(val, key) {
      this.val[key] = isPositiveInteger(val) ? val : ''
      this.handleEmit(key)
      this.handleEmitChange()
    },
    handleEmitChange() {
      this.$emit('change', { ...this.val })
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
.patch {
  .patch__separator {
    margin: 0 5px;
  }
  .patch__input ::v-deep {
    .el-input-group__prepend {
      padding: 0 5px;
      >div {
        display: inline-block;
      }
    }
    &.required .el-input-group__prepend  {
      &::before {
        content: '*';
        display: inline-block;
        margin-right: 4px;
        color: $--color-danger;
      }
    }
    .el-input-group__append {
      padding: 0 5px;
      background-color: #fff;
      transition: all .3s cubic-bezier(.645, .045, .355, 1);
    }
    .el-input__inner {
      padding: 0 5px;
      border-right: 0;
      &:hover + .el-input-group__append {
        border-color: $--input-border-color-hover;
      }
      &:focus + .el-input-group__append {
        border-color: $--input-border-color-focus !important;
      }
    }
  }
}
</style>