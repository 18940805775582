// PCB参数处理

// 是否显示判断
export const isShowPcbParams = params => ({
  // 是否显示TG值
  board_tg: params.blayer > 2,
  // 是否显示拼版规则
  layout: params.units == 2,
  // 是否显示工艺边框
  sidewidth: params.units != 3,
  // 是否显示内层铜厚
  insidecopper: params.blayer > 2,
  // 是否显示沉金厚度
  cjh: params.spray == '沉金',
  // 是否显示层压顺序
  overlay_type: params.blayer >= 4,
  // 是否显示叠层结构
  pressing: params.blayer > 2,
  // 是否显示报告形式
  report_type: ['出货报告', '切片报告', '阻抗报告'].some(i => params.report.includes(i)),
  // 是否显示拼版后尺寸
  pcb_size: params.units != 3
})

// 计算板子拼版尺寸
export const computePcbSize = params => {
  const { 
    width: bwidth, // 单片宽
    length: blength, // 单片长
    units, // 出货形式
    layoutx, // 列
    layouty, // 行
    sidewidth, // 工艺边宽度
    sidedirection, // 工艺边方向 
    pcs_cols_space, // 列距
    pcs_row_space, // 行距
    gap_cols_space, // 工艺边左右间距
    gap_row_space, // 工艺边上下间距
  } = params
  let width = Number(bwidth), length = Number(blength)
  // 出货形式 = 连片(华秋代拼)
  if (units == 2) {
    // 总行距
    const rowSpaceTotal = (pcs_row_space || 0) * ((layouty || 1) - 1) / 10
    // 总列距
    const colSpaceTotal = (pcs_cols_space || 0) * ((layoutx || 1) - 1) / 10

    width = width * (layouty || 1) + rowSpaceTotal
    length = length * (layoutx || 1) + colSpaceTotal
  }
  // 出货形式 != 连片(按文件)  需要加上工艺边
  if (units != 3) {
    const sidewidthTotal = sidewidth * 2 / 10
    // 上下工艺边间距
    const sideRowSpace = (gap_row_space || 0) * 2 / 10
    // 左右工艺边间距
    const sideColSpace = (gap_cols_space || 0) * 2 / 10

    // 上下 || 四边
    if (sidedirection == 'X' || sidedirection == 'XY') {
      width = width + sidewidthTotal + sideRowSpace
    }
    // 左右 || 四边
    if (sidedirection == 'Y' || sidedirection == 'XY') {
      length = length + sidewidthTotal + sideColSpace
    }
  }
  
  return { width: +width.toFixed(2), length: +length.toFixed(2) }
}

// 计算板子面积
export const computeAreas = params => {
  const { length, width } = computePcbSize(params)
  return length * width * params.bcount / 10000
}

// 连片数量换算为单片数量
export const pcsNumForSet = params => {
  const { layoutx, layouty, bcount } = params
  return layoutx * layouty * bcount || 0
}

// PCB参数格式化
export const formatPcbParams = (pcbParams, otherParams = {}) => {
  const params = { ...pcbParams, ...otherParams }
  const isShow = isShowPcbParams(params)
  // 当TG值隐藏时，清空TG值
  if (!isShow.board_tg) {
    params.board_tg = ''
  }
  // 拼版规则隐藏时，默认为1
  if (!isShow.layout) {
    params.layoutx = 1
    params.layouty = 1
  }
  // 工艺边框
  if (!isShow.sidewidth) {
    params.sidedirection = '无'
    params.sidewidth = 0
  }
  // 当内层铜厚隐藏时，传 0
  if (!isShow.insidecopper) {
    params.insidecopper = 0
  }
  // 层压顺序
  if (!isShow.overlay_type) {
    params.overlay_type = ''
    params.overlay_seq = ''
  } else {
    params.overlay_seq = params.overlay_type == 3 ? params.overlay_seq : ''
  }
  // 当叠层结构隐藏 或 为 无需求时，传 ''
  if (!isShow.pressing || params.pressing === '无需求') {
    params.pressing = ''
  }
  // 当报告形式隐藏时，传 0
  if (!isShow.report_type) {
    params.report_type = 0
  }
  // 订单支付方式
  if (params.deduct_type == 2) {
    params.deduct_limit = ''
  }
  // 品质赔付方式为 标准赔付 时，清空 PCBA单片成本价
  if (params.insurance_type == 0) {
    params.insurance = ''
  }

  // 产品报告
  params.report = params.report.includes('无需') ? '' : params.report.join(',')

  // 叠层结构
  if (params.pressing.includes('__')) {
    const [pressing_computer_id, pressing] = params.pressing.split('__')
    params.pressing = pressing
    params.pressing_computer_id = pressing_computer_id
  } else {
    params.pressing_computer_id = ''
  }
  delete params.pressing_data

  return params
}

// 当 表面处理 = OSP 时，尺寸：短边须 >= 5  长边须 >= 8
const validateOSPPcbSize = (sizes) => {
  const [min, max] = sizes.sort((a, b) => a - b)
  return min < 5 || max < 8
}
// PCB板子尺寸检验
const validatePcbSize = params => {
  const areas = computeAreas(params)
  const { length, width } = computePcbSize(params)
  const { beveledge, spray, blayer } = params
  if (length > 0 && width > 0) {
    return [
      {
        flag: beveledge == 1 && ((length < 4 || length > 28) || (width < 4 || width > 28)),
        tips: '手指斜边工艺，加工尺寸长和宽需满足4-28CM区间，请重新调整参数'
      },
      {
        flag: spray == 'OSP' && validateOSPPcbSize([length, width]),
        tips: 'OSP工艺，加工尺寸长和宽不能小于8*5cm ，请重新调整参数'
      },
      {
        flag: length < 1 || width < 1,
        tips: '加工尺寸长和宽都不能小于1CM，请修改板子尺寸或调整工艺边尺寸'
      },
      {
        flag: blayer < 4 && ((length > 50 && width > 50) || length > 60 || width > 60),
        tips: '单双面板长和板宽不能同时超过50厘米，大小须小于 60*50，如有疑问请联系华秋工作人员'
      },
      {
        flag: blayer >= 4 && ((length > 40 && width > 40) || length > 50 || width > 50),
        tips: '多层板板长或板宽大小须小于 50*40，如有疑问请联系华秋工作人员'
      },
      {
        flag: spray !== 'OSP' && areas >= 5 && [length, width].every(i => i < 5),
        tips: '非OSP工艺，面积≥5㎡，加工尺寸不能小于5*5cm，请重新调整参数'
      }
    ]
  }
  return []
}
// PCB计价页下单校验
export const validatePcbQuote = params => {
  const isShow = isShowPcbParams(params)
  const { length, width } = computePcbSize(params)
  const valids = [
    {
      key: 'board_brand',
      flag: !params.board_brand, 
      tips: '请选择板材品牌'
    },
    {
      key: 'pbnum',
      flag: !params.pbnum, 
      tips: '请输入拼版款数'
    },
    {
      key: 'pbnum',
      flag: isNaN(Number(params.pbnum)) || Number(params.pbnum) <= 0 || Number(params.pbnum) % 1 !== 0, 
      tips: '拼版款数，需为1的正整数倍，请修改拼版款数'
    },
    {
      key: 'length',
      flag: isNaN(Number(params.length)) || Number(params.length) <= 0 || isNaN(Number(params.width)) || Number(params.width) <= 0, 
      tips: '请输入板子大小'
    },
    {
      key: 'layout',
      flag: isShow.layout && (!params.layoutx || !params.layouty), 
      tips: '请输入拼版规则'
    },
    {
      key: 'bcount',
      flag: params.bcount <= 0, 
      tips: '请选择板子数量'
    },
    {
      key: 'sidewidth',
      flag: params.sidedirection != '无' && params.sidewidth < 3, 
      tips: '工艺边框最小3mm'
    },
    {
      key: 'sidewidth',
      flag: params.sidedirection != '无' && params.sidewidth * 10 % 5 != 0, 
      tips: '工艺边框仅支持0.5的倍数，比如3.5, 4 , 4.5'
    },
    {
      key: 'bheight',
      flag: !params.bheight, 
      tips: '请选择板子厚度'
    },
    {
      key: 'insidecopper',
      flag: isShow.insidecopper && !params.insidecopper, 
      tips: '请选择内层铜厚'
    },
    {
      key: 'spray',
      flag: () => {
        if (params.spray === 'OSP' && length > 0 && width > 0) {
          return validateOSPPcbSize([length, width])
        }
        return false
      }, 
      tips: 'OSP工艺，加工尺寸长和宽不能小于8*5cm ，请重新调整参数'
    },
    {
      key: 'spray',
      flag: () => {
        if (params.spray === 'OSP' && length > 0 && width > 0) {
          const areas = computeAreas(params)
          return areas > 3 && params.spray == 'OSP' && params.cover == '过孔盖油'
        }
        return false
      },
      tips: '您当前选择的大于3平米的OSP工艺订单，阻焊覆盖不支持过孔盖油，需您重新调整参数。'
    },
    {
      key: 'overlay_type',
      flag: isShow.overlay_type && !params.overlay_type, 
      tips: '请选择层压顺序'
    },
    {
      key: 'overlay_type',
      flag: isShow.overlay_type && params.overlay_type == 3 && !params.overlay_seq, 
      tips: '请填写层压顺序'
    },
    {
      key: 'pressing',
      flag: isShow.pressing && !params.pressing, 
      tips: '请选择您需要的叠层结构'
    },
    {
      key: 'beveledge',
      flag: params.beveledge == 1 && ((length < 4 || length > 28) || (width < 4 || width > 28)), 
      tips: '手指斜边工艺，加工尺寸长和宽需满足4-28CM区间，请重新调整参数'
    },
    {
      key: 'review_file',
      flag: !params.review_file && params.review_file !== 0, 
      tips: '请选择确认生产稿'
    },
    {
      key: 'report_type',
      flag: isShow.report_type && !params.report_type, 
      tips: '请选择报告形式'
    },
    {
      key: 'paper',
      flag: !params.paper, 
      tips: '请选择隔白纸'
    },
    {
      key: 'user_stamp',
      flag: !params.user_stamp, 
      tips: '请选择客编'
    },
    {
      key: 'deduct_type',
      flag: !params.deduct_type, 
      tips: '请选择订单支付方式'
    },
    {
      key: 'deduct_type',
      flag: params.deduct_type == 1 && !params.deduct_limit && !['0.00', '0', 0].includes(params.deduct_limit), 
      tips: '请填写自动扣款差额范围'
    },
    {
      key: 'is_need_smt',
      flag: !params.is_need_smt, 
      tips: '请选择是否需要我司做SMT'
    },
    ...validatePcbSize(params),
  ]

  return valids.filter(item => {
    const flag = typeof item.flag === 'function' ? item.flag() : item.flag
    return flag
  })
}
