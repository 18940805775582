<!-- 刷三防漆 -->
<template>
  <BaseFormItem
    class="need_conformal_coating"
    :label="fields.label"
    :labelTips="fields.labelTips"
  >
    <BaseRadioGroup v-model="val.need_conformal_coating" @change="handleChange">
      <el-radio-button
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
    <template v-if="val.need_conformal_coating !== 0">
      <BaseCheckbox 
        class="need_conformal_coating__checkbox" 
        v-model="val.is_dodge_solder_joint"
        :true-label="1"
        :false-label="0"
        @change="handleUpdate"
      >
        规避焊点及测试点
      </BaseCheckbox>
      <BaseTipPopover content="三防喷漆布原则不规避零件焊点及测试点，如需规避请勾选" />
    </template>
  </BaseFormItem>
</template>

<script>
import { getSMTFields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    // 规避焊点及测试点
    is_dodge_solder_joint: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      val: {
        need_conformal_coating: this.value,
        is_dodge_solder_joint: this.is_dodge_solder_joint
      }
    }
  },
  computed: {
    fields() {
      return getSMTFields('need_conformal_coating')
    }
  },
  watch: {
    value(newVal) {
      this.val.need_conformal_coating = newVal
    },
    is_dodge_solder_joint(newVal) {
      this.val.is_dodge_solder_joint = newVal
    }
  },
  methods: {
    handleEmit() {
      const val = this.val.need_conformal_coating
      this.$emit('input', val)
      this.$emit('change', val)
    },
    handleUpdate() {
      this.$emit('update:is_dodge_solder_joint', this.val.is_dodge_solder_joint)
    },
    handleChange(val) {
      this.handleEmit()
      if (val === 0) {
        this.val.is_dodge_solder_joint = 0
        this.handleUpdate()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.need_conformal_coating {
  .need_conformal_coating__checkbox {
    margin: 0 10px;
  }
}
</style>