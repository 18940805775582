<!-- PCB拼版尺寸 -->
<template>
  <BaseFormItem
    class="pcb_ban_size"
    :unit="fields.unit"
    :label="fields.label"
    :labelTips="fields.labelTips"
    :fillTips="fillTips"
  >
    <BaseInput
      v-model="val.pcb_ban_width"
      :maxlength="5"
      width="84px"
      placeholder="长/x"
      @input="val => $emit('update:pcb_ban_width', val)"
      @change="() => handleEmitChange('pcb_ban_width')"
    ></BaseInput>
    <span class="pcb_ban_size__linker">x</span>
    <BaseInput
      v-model="val.pcb_ban_height"
      :maxlength="5"
      width="84px"
      placeholder="宽/y"
      @input="val => $emit('update:pcb_ban_height', val)"
      @change="() => handleEmitChange('pcb_ban_height')"
    ></BaseInput>
  </BaseFormItem>
</template>

<script>
import { getSMTFields } from '@@/utils/getFields'

export default {
  props: {
    pcb_ban_width: {
      type: [String, Number],
      default: ''
    },
    pcb_ban_height: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      val: {
        pcb_ban_width: this.pcb_ban_width,
        pcb_ban_height: this.pcb_ban_height
      }
    }
  },
  computed: {
    fields() {
      return getSMTFields('pcb_ban_size')
    },
    // 是否显示未填写样式
    fillTips() {
      const { pcb_ban_width, pcb_ban_height } = this.val
      return !pcb_ban_width && !pcb_ban_height ? '请填写' : ''
    }
  },
  watch: {
    pcb_ban_width(pcb_ban_width) {
      this.val.pcb_ban_width = pcb_ban_width
    },
    pcb_ban_height(pcb_ban_height) {
      this.val.pcb_ban_height = pcb_ban_height
    }
  },
  methods: {
    handleEmit(key) {
      this.$emit(`update:${key}`, this.val[key])
    },
    handleEmitChange(key) {
      let val = parseFloat(this.val[key])
      if (isNaN(val) || val < 0) {
        val = ''
      } else {
        val = Math.round(val * 100) / 100
      }
      this.val[key] = val
      this.handleEmit(key)
      this.$emit('change', { ...this.val })
    }
  }
}
</script>

<style lang="scss" scoped>
.pcb_ban_size {
  .pcb_ban_size__linker {
    margin: 0 8px;
    color: #BBBBBB;
  }
}
</style>