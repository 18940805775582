<!-- 费用展示 -->
<template>
  <div class="price-view">
    <ul>
      <li v-for="(item, index) in data">
        <span class="price-view__label">
          {{ item.label }}：
        </span>
        <span 
          :class="{ 
            'price-view__value': true, 
            'has-tips': item.tips,
            'has-handle': item.handle
          }"
          :style="{ color: item.color }"
        >
          <!-- 点击icon -->
          <BaseTipPopover v-if="item.handle" disabled @click.native="item.handle" />
          <!-- 提示icon -->
          <BaseTipPopover v-if="item.tips" :content="item.tips" />
          {{ `${item.symbol || ''}${item.hideUnit ? '' : '￥'}${item.value}` }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: []
    }
  }
}
</script>

<style lang="scss" scoped>
.price-view {
  padding: 12px;
  border: 1px dashed $--border-color-base;
  border-radius: $--border-radius-base;
  // background: url(~@@/assets/img/common/price-bg.png);
  // background-size: 58px;
  // background-repeat: no-repeat;
  // background-position: 30% center;
  >ul {
    line-height: 24px;
    text-align: right;
    >li {
      display: flex;
      justify-content: flex-end;
    }
  }
  .price-view__label {

  }
  .price-view__value {
    display: block;
    width: 88px;
    &.has-tips,
    &.has-handle {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &.has-handle {
      .base-tip-popover__icon {
        cursor: pointer;
      }
    }
  }
}
</style>