<!-- affix 固钉组件 -->
<template>
  <div class="affix" :style="wrapStyle">
    <div ref="affixContainer" :class="{'affix-container': affixed}" :style="styles">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { isPcbOrSmtSite } from '@@/utils/is'
export default {
  props: {
    offset: {
      type: Number,
      default: 0
    },
    onAffix: {
      type: Function,
      default: () => ({})
    },
    // 需要监听 el-scrollbar 的滚动事件，所以默认为 el-scrollbar 的id
    boundary: {
      type: String,
      default: '#mainPageScrollbar'
    },
    relativeContainerId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      affixed: false,
      styles: {},
      affixedClientHeight: 0,
      affixedClientTop: 0,
      wrapStyle: {},
      scrollWrap: null
    }
  },
  methods: {
    getScroll(w, top) {
      let ret = w[`page${(top ? 'Y' : 'X')}Offset`]
      const method = `scroll${top ? 'Top' : 'Left'}`
      if (typeof ret !== 'number') {
        const d = w.document
        // ie6,7,8 standard mode
        ret = d.documentElement[method]
        if (typeof ret !== 'number') {
          // quirks mode
          ret = d.body[method]
        }
      }
      return ret
    },
    getOffset(element) {
      const rect = element.getBoundingClientRect()
      const body = document.body
      const clientTop = element.clientTop || body.clientTop || 0
      const clientLeft = element.clientLeft || body.clientLeft || 0
      const scrollTop = this.getScroll(window, true)
      const scrollLeft = this.getScroll(window)
      return {
        top: rect.bottom + scrollTop - clientTop - this.affixedClientHeight,
        left: rect.left + scrollLeft - clientLeft
      }
    },
    handleScroll() {
      // const scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
      const relativeContainer = document.getElementById(this.relativeContainerId)
      if (!relativeContainer) return
      const rRect = relativeContainer.getBoundingClientRect()
      const rect = this.$refs.affixContainer.getBoundingClientRect()

      let offset = this.offset
      // PCB站点/SMT站点
      if (isPcbOrSmtSite) {
        offset = Math.max(0, rRect.top)
      }
      
      this.affixed = true
      this.styles = { 
        top: `${Math.min(rRect.bottom - rect.height, offset)}px`, 
        left: `${rRect.right}px` 
      }

      // const scrollTop = this.getScroll(window, true) + this.offsets
      // const elementOffset = this.getOffset(this.$el)
      // if (!this.affixed && scrollTop > elementOffset.top) {
      //   this.affixed = true
      //   this.styles = {
      //     top: `${this.offsets}px`,
      //     left: `${elementOffset.left}px`,
      //     width: `${this.$el.offsetWidth}px`
      //   };
      //   this.onAffix(this.affixed)
      // }
      // if (this.boundary && scrollTop > elementOffset.top) {
      //   const el = document.getElementById(this.boundary.slice(1))
      //   if (el) {
      //     const boundaryOffset = this.getOffset(el)
      //     if ((scrollTop + this.offsets) > boundaryOffset.top) {
      //       const top = scrollTop - boundaryOffset.top
      //       this.styles.top = `-${top}px`
      //     }
      //   }
      // }
      // if (this.affixed && scrollTop < elementOffset.top) {
      //   this.affixed = false
      //   this.styles = {}
      //   this.onAffix(this.affixed)
      // }
      // if (this.affixed && this.boundary) {
      //   const el = document.getElementById(this.boundary.slice(1))
      //   if (el) {
      //     const boundaryOffset = this.getOffset(el)
      //     if ((scrollTop + this.offsets) <= boundaryOffset.top) {
      //       this.styles.top = this.affixedClientTop
      //     }
      //   }
      // }
    }
  },
  computed: {
    offsets() {
      if (this.boundary) {
        return 0;
      }
      return this.offset
    }
  },
  mounted() {
    const { width, height, top } = this.$refs.affixContainer.getBoundingClientRect()
    this.affixedClientHeight = height
    this.affixedClientTop = top
    this.wrapStyle = {height: `${height}px`, width: `${width}px`}

    const boundaryEl = document.querySelector(this.boundary)
    this.scrollWrap = boundaryEl ? boundaryEl.querySelector('.el-scrollbar__wrap') : window
    this.scrollWrap.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleScroll)
    this.handleScroll()
  },
  beforeDestroy() {
    this.scrollWrap.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleScroll)
  }
};
</script>
<style lang="scss" scoped>
.affix {
  .affix-container {
    position: fixed;
  }
}
</style>