<!-- PCB FPC SMT PCB+SMT 计价页右侧 -->
<template>
  <div class="quote-right quote-container__right quote-container__content">
    <!-- SMT + PCB/FPC 生产周期 -->
    <SmtDeltime
      v-if="isNeedSmt"
      :showPcb="this.type.includes('_')"
      :smtPrice="smtPrice"
      :pcbDelTime="pcbPrice.deltime"
      :options="deltimeOptions"
      :colorTips="deltimeColorTips"
      @change="pcbDeltimeChange" 
    />
    <template v-else>
      <!-- PCB 生产周期 -->
      <PcbDeltime 
        :value="pcbPrice.deltime"
        :options="deltimeOptions" 
        :colorTips="deltimeColorTips"
        @change="pcbDeltimeChange" 
      />
      <!-- 发货时间 -->
      <DeliveryTime :time="deliveryDate.time" :tips="deliveryDate.tips" />
    </template>
    <!-- 制板费明细 -->
    <PriceDetails
      :type="type"
      :pcbPrice="pcbPrice"
      :smtPrice="smtPrice"
      :isFpcNeedAudit="isFpcNeedAudit"
      :spray="getPcbFieldValue('spray')"
    />
    <!-- 运费/税费试算 -->
    <el-form size="small" label-width="100px">
      <!-- FPC 高层板(8, 10, 12)不显示快递、发票 -->
      <template v-if="!isFpcNeedAudit">
        <p class="quote-container__right__title has-border">
          运费/税费试算
          <span v-if="priceTotal.weight > 0">重量(预估)：{{ priceTotal.weight }} kg</span>
        </p>
        <!-- 省市区 -->
        <BaseFormItem class="quote-right__formItem" label="配送至" >
          <ChooseAddressCascader 
            width="100%"
            @change="handleAddressChange"
          />
        </BaseFormItem>
        <!-- 快递公司 -->
        <BaseFormItem class="quote-right__formItem" label="快递公司" >
          <ExpressSelect
            width="100%"
            :value="getPcbFieldValue('express')" 
            :mountRef="(ref) => (expressRef = ref)" 
            :type="isNeedSmt ? 'SMT' : 'PCB'"
            :params="expressParams" 
            @change="handleExpressChange"
          />
        </BaseFormItem>
        <!-- 发票类型 -->
        <BaseFormItem class="quote-right__formItem" label="开票类型" >
          <InvoiceType
            uiType="select"
            :value="getPcbFieldValue('invoice')" 
            :hideOptios=" ['增票', isNeedSmt ? '不需要' : '']"
            @change="handleInvoiceTypeChange"
          />
        </BaseFormItem>
      </template>
      <!-- PCBA 总费用明细 -->
      <SmtPriceTotalView 
        v-if="isNeedSmt"
        :data="priceTotal" 
        :type="type" 
        :isFpcNeedAudit="isFpcNeedAudit" 
        :showPcb="smtParams.custom_pcb_ban == 1"
        :buy_bom="smtParams.buy_bom"
        :modelSelectionType="smtParams.order_info.model_selection_type"
      />
      <!-- PCB 总费用明细 -->
      <PcbPriceTotalView 
        v-else
        :data="priceTotal" 
        :type="type" 
        :isFpcNeedAudit="isFpcNeedAudit" 
      />
      <!-- 提交订单 -->
      <div class="quote-right__submit">
        <div class="quote-right__submit__price">
          预估总价：
          <span v-if="isFpcNeedAudit">
            ----
          </span>
          <template v-else>
            <span>￥</span>
            <span>
              <QuoteAnimateNumber :num="priceTotal.total_amount" />
            </span>
          </template>
        </div>
        <p class="quote-right__submit__tips">
          <template v-if="isFpcNeedAudit && type === 'FPC'">
            软硬结合板具体价格<span>以审核后为准</span>
          </template>
          <template v-else>
            仅供参考，最终价格以审核后为准
          </template>
        </p>
        <p v-if="isNeedSmt && deliveryTips" class="quote-right__submit__deliveryTips">
          {{ deliveryTips }}
        </p>
        <div class="quote-right__submit__btns">
          <!-- PCBA返单下一步 -->
          <BaseButton 
            v-if="isPcbaReturn"
            size="" 
            round 
            class="quote-right__submit__btn"
            style="width: 310px" 
            :loading="smtParams.saveLoading"
            @click="$emit('save')"
          >
            下一步
            <i class="el-icon-d-arrow-right price-submit-icon"></i>
          </BaseButton>
          <template v-else>
            <el-popover
              v-if="isShowOnlyPcbBtn"
              placement="top-start"
              width="200"
              trigger="hover"
              content="若您当前只需要下PCB，请点击此按钮"
            >
              <BaseTextButton 
                slot="reference"
                style="width: 120px" 
                class="quote-right__submit__btn only-pcb"
                :plain="false" 
                :loading="onlyPcbLoading"
                @click="handleOnlyPcb"
              >
                只下PCB
              </BaseTextButton>
            </el-popover>
            <BaseButton 
              size="" 
              round 
              class="quote-right__submit__btn"
              style="width: 240px" 
              :loading="saveLoading" 
              @click="handleSave"
            >
              {{ isShowBomBtn ? `下一步，${isResetBom ? '修改' : '去'}BOM选型` : (isEdit ? '提交修改' : '立即下单') }}
              <i class="el-icon-d-arrow-right price-submit-icon"></i>
            </BaseButton>
          </template>
        </div>
      </div>
    </el-form>
    <!-- 登录弹框 -->
    <LoginDialog ref="LoginDialogRef" @close="hanleLoginDialogClose" />
  </div>
</template>

<script>
// 登录弹框
import LoginDialog from '@@/components/loginDialog/index'
// 省市区
import ChooseAddressCascader from '@@/components/address/chooseAddressCascader'
// 快递公司
import ExpressSelect from '@@/components/expressSelect/index'
import { SMT_EXPRESS_LINKER } from '@@/components/expressSelect/list'
// 发票类型
import InvoiceType from '@@/components/invoice/invoiceType'
// 数字跳动效果
import QuoteAnimateNumber from '@@/components/common/quoteAnimateNumber'
// PCB 生产周期
import PcbDeltime from './deltime/pcbDeltime'
// SMT 生产周期
import SmtDeltime from './deltime/smtDeltime'
// PCB 发货时间
import DeliveryTime from './deliveryTime'
// 制板费明细
import PriceDetails from './priceDetails'
// 总费用明细
// import PriceTotalView from './priceTotalView'
import PcbPriceTotalView from './priceTotalView/pcb'
import SmtPriceTotalView from './priceTotalView/smt'

import { sensorsTrack } from '@@/utils/sensors'
import { debounce } from '@@/utils/utils'
import { targetSelfWindow } from '@@/utils/triggerParent'
import { SMT_DEFAULT_FORM } from '@@/utils/defaultForm'
import { ORDER_TYPE_ENUMS } from '@@/utils/constant'
import { getBomUrl } from '@@/utils/bom'
import { formatPcbParams, validatePcbQuote } from '@@/utils/pcbParamsFormat'
import { formatFpcParams, validateFpcQuote, isFpcNeedAudit } from '@@/utils/fpcParamsFormat'
import { formatSmtParams, validateSmtQuote, formatDeliveryTips } from '@@/utils/smtParamsFormat'
import { savePcbQuote } from '@@/api/pcb'
import { bomInitialization, saveOrderTemp } from '@@/api/pcba'
import { isPcbSite, isSmtSite, isMyCenterSite } from '@@/utils/is'

// 未登录时，暂存PCB提交参数的key
const BEFORE_LOGIN_PCB_PARAMS_KEY = 'BEFORE_LOGIN_PCB_PARAMS_KEY'

export default {
  props: {
    type: {
      type: String,
      validator: function (value) {
        return ['PCB', 'FPC', 'SMT', 'PCB_SMT', 'FPC_SMT', 'SMT_PCB', 'SMT_FPC'].includes(value)
      }
    },
    // 是否是PCBA返单页
    isPcbaReturn: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LoginDialog,
    PcbDeltime,
    SmtDeltime,
    DeliveryTime,
    PriceDetails,
    ChooseAddressCascader,
    ExpressSelect,
    InvoiceType,
    // PriceTotalView,
    PcbPriceTotalView,
    SmtPriceTotalView,
    QuoteAnimateNumber
  },
  // 来源：PCB FPC SMT 计价页
  inject: {
    pcbParams: { default: {} },
    fpcParams: { default: {} },
    smtParams: { default: { ...SMT_DEFAULT_FORM } },
    oldBomFieldsData: { default: () => {} }, // 来源：SMT计价页-修改，用于比较BOM选型相关的字段值是否有修改
    getPcbPrice: { default: () => ({}) },
    getSmtPrice: { default: () => ({}) },
    getPriceTotal: { default: () => ({}) },
    // 将校验结果传回计价页
    pcbValidate: { default: () => {} },
    fpcValidate: { default: () => {} },
    smtValidate: { default: () => {} },
  },
  data() {
    return {
      saveLoading: false,
      onlyPcbLoading: false,
      debounceGetExpress: debounce(this.handleGetExpressList, 300), // 获取快递列表防抖
    }
  },
  computed: {
    // PCB站点下的提交页path
    submitPath() {
      return this.type === 'FPC' ? '/quote_new/fpc/submit' : '/quote_new/pcb/submit'
    },
    // 获取实时的计价数据
    pcbPrice() {
      return this.getPcbPrice()
    },
    // 获取实时的计价数据
    smtPrice() {
      return this.getSmtPrice()
    },
    priceTotal() {
      return this.getPriceTotal()
    },
    // 是否有SMT
    isNeedSmt() {
      return this.type.includes('SMT')
    },
    // FPC 板子层数为 8 10 12 价格以审核为准
    isFpcNeedAudit() {
      const { type, fpcParams } = this
      return isFpcNeedAudit(type, fpcParams)
    },
    // PCB生产周期选项
    deltimeOptions() {
      const { suggest = {} } = this.pcbPrice
      return suggest.deltime || []
    },
    // 生产周期 油墨提示
    deltimeColorTips() {
      const { deltimeOptions, pcbPrice: { unsought = '' }, pcbParams, fpcParams, type } = this
      const deltime = type.includes('FPC') ? fpcParams.deltime : pcbParams.deltime
      const firstItem = deltimeOptions[0] || {}
      const currentDelTime = deltimeOptions.find(i => i.name === deltime) || firstItem
      // 推荐选项
      const recommendItem = deltimeOptions.find(i => i.type == 2)
      let tips = currentDelTime.type == 1 && recommendItem ? `当前为冷偏工艺，交期需顺延${firstItem.name}，建议选择${recommendItem.color}` : ''
      // 冷偏工艺提示
      tips = {
        1: '当前为极冷偏工艺，将加收极冷偏工艺费，建议调整油墨颜色和板厚等参数',
        2: `当前为冷偏工艺，交期需顺延${firstItem ? firstItem.name : ''}，建议调整油墨颜色和板厚等参数`
      }[unsought] || tips
      return tips
    },
    // PCB 发货时间
    deliveryDate() {
      const { shipdate, suggest = {} } = this.pcbPrice
      return {
        time: shipdate,
        tips: suggest.timetips || ''
      }
    },
    // 获取快递列表参数
    expressParams() {
      const { type, isNeedSmt, pcbParams, fpcParams, smtParams, isPcbaReturn } = this
      const params = type.includes('FPC') ? fpcParams : pcbParams
      // PCBA订单
      if (isNeedSmt) {
        const { number, province_id, custom_pcb_ban, pcb_ban_width, pcb_ban_height, pcb_ban_x, pcb_ban_y, packing_type, pcbParams } = smtParams
        const { length, width, layoutx, layouty, blength, bwidth } = custom_pcb_ban == 2 ? pcbParams : params
        const pcbSizeParams = {
          // 在华秋定制
          1: { length, width, layoutx, layouty, pcb_ban_height: 0, pcb_ban_width: 0 },
          // 已在华秋下单
          2: { length: 1, width: 1, layoutx, layouty, pcb_ban_height: 0, pcb_ban_width: 0 },
          // 自己提供
          3: { length: 0, width: 0, layoutx: pcb_ban_x, layouty: pcb_ban_y, pcb_ban_height, pcb_ban_width },
        }[custom_pcb_ban] || {}
        return { ...pcbSizeParams, number, province_id, packing_type }
      }
      // PCB订单
      return { ...params }
    },
    // 是否是修改页
    isEdit() {
      return !!this.$route.query.id
    },
    // 显示只下PCB按钮
    isShowOnlyPcbBtn() {
      // SMT下单
      // PCB 等于 在华秋定制
      return this.isNeedSmt && this.smtParams.custom_pcb_ban == 1
    },
    // BOM是否需要重新选型
    isResetBom() {
      // 从BOM返回的都需要重配
      if (this.$route.query.source === 'bom') return true
      if (!this.oldBomFieldsData) return false
      const { isEdit, isNeedSmt, smtParams } = this
      const oldBomFieldsData = this.oldBomFieldsData()
      const isBomEdit = !!smtParams.order_info.bom_self_id && smtParams.buy_bom == 4
      if (!isEdit || !isNeedSmt || !isBomEdit || Object.keys(oldBomFieldsData).length === 0) return false
      // BOM相关字段值改变时，需要重配
      return Object.keys(oldBomFieldsData).some(key => {
        const newVal = smtParams[key]
        const oldVal = oldBomFieldsData[key]
        // // 数量由多改少 不需要重配
        // if (key === 'number') {
        //   return newVal > oldVal
        // }
        // // 贴片面由双改单 不需要重配 
        // if (key === 'single_or_double_technique') {
        //   return newVal !== oldVal && oldVal != 2
        // }
        if (key === 'buy_bom') {
          return newVal !== oldVal && newVal == 4
        }
        return newVal !== oldVal
      })
    },
    // 显示去BOM选型按钮
    isShowBomBtn() {
      const { isEdit, isNeedSmt, isResetBom, smtParams } = this
      // BOM物料 不等于 自助匹配选型
      if (smtParams.buy_bom != 4) {
        return false
      }
      if (isEdit) {
        // 修改页 BOM相关字段改变时才显示
        return isResetBom
      }
      // SMT下单
      return isNeedSmt
    },
    deliveryTips() {
      return formatDeliveryTips(this.priceTotal.activity || {})
    }
  },
  watch: {
    // PCB/FRP生产周期
    'pcbPrice.deltime'(val) {
      this.pcbParams.deltime = val
      this.fpcParams.deltime = val
    },
    // 获取快递列表参数改变时，重新调用获取快递列表接口
    expressParams(val) {
      this.debounceGetExpress()
    }
  },
  created() {
    // PCB站点下，如果有暂存的PCB提交参数，直接用暂存的参数提交
    if (isPcbSite) {
      const beforeLoginPcbParams = sessionStorage.getItem(BEFORE_LOGIN_PCB_PARAMS_KEY)

      if (beforeLoginPcbParams) {
        sessionStorage.removeItem(BEFORE_LOGIN_PCB_PARAMS_KEY)
        const { type, params } = JSON.parse(beforeLoginPcbParams)
        this.savePcbParams(type, params)
      }
    }
  },
  methods: {
    // 获取PCB/FPC字段值
    getPcbFieldValue(field) {
      const { type, pcbParams, fpcParams } = this
      if (type.includes('FPC')) return fpcParams[field]
      return pcbParams[field]
    },
    // 交期改变
    pcbDeltimeChange(deltime) {
      const { color, bheight } = this.deltimeOptions.find(item => item.name == deltime) || {}
      const data = { deltime, color, bheight: Number(bheight) }
      this.pcbParams = Object.assign(this.pcbParams, { ...data })
      this.fpcParams = Object.assign(this.fpcParams, { ...data })
    },
    // 省市区改变
    handleAddressChange(item) {
      this.pcbParams = Object.assign(this.pcbParams, item)
      this.fpcParams = Object.assign(this.fpcParams, item)
      this.smtParams.province_id = item.province
      this.handleGetExpressList()
    },
    // 快递改变
    handleExpressChange(item = {}) {
      const { shipping_id = '', shipping_pay_type = '' } = item
      this.pcbParams.express = shipping_id
      this.fpcParams.express = shipping_id
      if (this.isNeedSmt) {
        this.smtParams = Object.assign(this.smtParams, {
          express: shipping_id ? shipping_id.split(SMT_EXPRESS_LINKER)[0] : '',
          express_type: shipping_pay_type,
        })
      }
    },
    // 获取快递选项
    handleGetExpressList() {
      this.$nextTick(() => {
        this.expressRef.getList()
      })
    },
    // 发票类型改变
    handleInvoiceTypeChange(item) {
      this.pcbParams.invoice = item
      this.fpcParams.invoice = item
      this.smtParams.order_info.vat_type = { '不需要': 3, '普票': 5, '增票': 1, '增票全电': 4 }[item]
    },

    // PCB/FPC立即下单神策埋点
    pcbSensorsTrack(has_result) {
      const type = this.type
      if (!['PCB', 'FPC'].includes(type)) return
      const eventName = {
        PCB: 'PCB1_quotation_order_click',
        FPC: 'FPC1_quotation_order_click'
      }[type]
      const data = {
        has_result,
        title: document.title,
        url: document.location.href,
        entrance: { PCB: '新版PCB计价：立即下单', FPC: '新版FPC计价：立即下单' }[type]
      }
      // 神策数据埋点
      sensorsTrack(eventName, data)
    },

    // PCB校验
    validatePcb(isValidateSmt) {
      if (this.type.includes('PCB')) {
        const valids = validatePcbQuote(this.pcbParams)
        if (valids.length > 0) {
          // 在SMT计价页校验时，不在这里调用 pcbValidate
          !isValidateSmt && this.pcbValidate(valids)
          return valids
        }
      }
      if (this.type.includes('FPC')) {
        const valids = validateFpcQuote(this.fpcParams)
        if (valids.length > 0) {
          // 在SMT计价页校验时，不在这里调用 fpcValidate
          !isValidateSmt && this.fpcValidate(valids)
          return valids
        }
      }
      return []
    },
    // SMT校验 
    validateSmt() {
      const validsSmt = validateSmtQuote(this.smtParams)
      // 不是仅下SMT时，需要校验PCB信息
      const validsPcb = this.type !== 'SMT' ? this.validatePcb(true) : []

      // 在PCB信息之前的SMT校验字段
      const inPcbBeforeFields = ['application_sphere', 'buy_bom', 'custom_pcb_ban']
      // SMT校验字段
      const validsSmtKey = validsSmt.map(item => item.key)
      // 计算 inPcbBeforeFields 在SMT校验字段中最后一个的位置
      const pcbInsertIndex = Math.max(...validsSmtKey.map((key, index) => inPcbBeforeFields.includes(key) ? index : -1)) + 1
      // 将PCB的校验错误信息插入到 inPcbBeforeFields 之后的位置
      validsSmt.splice(pcbInsertIndex, 0, ...validsPcb)

      if (validsSmt.length > 0) {
        this.smtValidate(validsSmt)
        if (validsPcb.length > 0) {
          this.type.includes('FPC') ? this.fpcValidate(validsSmt) : this.pcbValidate(validsSmt)
        }
        return validsSmt
      }
      return []
    },
    
    // 立即下单
    async handleSave() {
      try {
        this.saveLoading = true
        // 需要SMT贴片时，调用SMT保存接口 否则调用PCB保存接口
        const res = this.isNeedSmt ? await this.saveSmtQuote() : await this.savePcbQuote()
        this.saveLoading = false
        if (!res) return
        // PCB站点
        if (isPcbSite) {
          this.$router.push({
            path: this.submitPath,
            query: { type: ORDER_TYPE_ENUMS[this.type], id: res.id }
          })
        } else if (isSmtSite) {
          // SMT站点
          const { id, bomId } = res
          // 跳转到BOM自助选型
          if (this.isShowBomBtn) {
            location.href = getBomUrl({ bomId, id, type: this.type })
          } else {
            this.$router.push({
              path: '/quote/submit',
              query: { type: ORDER_TYPE_ENUMS[this.type], id: res.id }
            })
          }
        } else {
          // 用户中心站点
          const { id, bomId } = res
          // 跳转到BOM自助选型
          if (this.isShowBomBtn) {
            location.href = getBomUrl({ bomId, id, type: this.type })
          } else {
            // 跳转到对应的提交页（共用一个提交页，路由不同）
            const path = this.$route.path.replace('quote', 'submit')
            targetSelfWindow({ path, query: { type: ORDER_TYPE_ENUMS[this.type], id, source: 'quote' } })
          }
        }
      } catch (error) {
        console.log(error)
        this.saveLoading = false
      }
    },
    // 暂存PCB计价信息
    async savePcbQuote({ params, isOnlyPcb } = {}) {
      if (!params) {
        const valids = this.validatePcb()
        if (valids.length > 0) return false

        params = this.type.includes('FPC') ? formatFpcParams(this.fpcParams) : formatPcbParams(this.pcbParams)
      }

      // 只下PCB时，需要弹框提示二次确认
      if (isOnlyPcb) {
        const isConfirm = await this.confirmOnlyPcb()
        if (!isConfirm) return false
        // SMT贴片设为 不需要
        params.is_need_smt = 2
        params.pcbfile = this.smtParams.file_pcb
      }
      
      return await savePcbQuote(params)
        .then(res => {
          if (!res) return false
          const { retCode, retMsg, result } = res

          // PCB站点 且 未登录
          if (retCode === 105008 && isPcbSite) {
            // 暂存PCB提交参数
            sessionStorage.setItem(BEFORE_LOGIN_PCB_PARAMS_KEY, JSON.stringify({ type: this.type, params }))
            this.$refs.LoginDialogRef.open()
            return
          }

          const { id, data, error_code, error_msg, suggest } = result || {}
          if (retCode === 0 && error_code === 0) {
            this.pcbSensorsTrack(true)
            return { id, data }
          }
          this.pcbSensorsTrack(false)
          if (retCode === 0 && suggest) {
            this.$confirm(error_msg, '温馨提示', {
              confirmButtonText: '同意变更',
              cancelButtonText: '不同意',
              lockScroll: false,
              center: true,
              dangerouslyUseHTMLString: true,
              closeOnClickModal: false,
              closeOnPressEscape: true
            }).then(() => {
              this.pcbParams = Object.assign(this.pcbParams, suggest)
              isOnlyPcb ? this.handleOnlyPcb() : this.handleSave()
              return false
            }).catch(() => {})
          } else {
            this.$message.error(error_msg || retMsg || '未知错误，请稍后重试')
            return false
          }
        })
    },
    // PCB + SMT 暂存
    async saveSmtQuote() {
      try {
        const valids = this.validateSmt()
        if (valids.length > 0) return false

        const params = formatSmtParams(this.smtParams)
        // 不是仅下SMT时，需要先暂存PCB订单
        if (this.type !== 'SMT') {
          // PCB + SMT一起下单 关联PCB文件
          const { custom_pcb_ban, file_pcb } = params.smt_params
          if (custom_pcb_ban == 1 && file_pcb) {
            this.pcbParams.pcbfile = file_pcb
          }

          const pcbRes = await this.savePcbQuote()
          if (!pcbRes) return false
          const { data = {}, id } = pcbRes
          params.pcb_params = {...data, quoteid: id}
          // PCB + SMT一起下单 且有PCB文件 将PCB文件关联到SMT
          if (params.smt_params.custom_pcb_ban == 1 && data.pcbfile) {
            params.smt_params.file_pcb = data.pcbfile
            params.smt_params.pcb_file_name = data.pcbfile.split('/').pop()
          }
        }

        // BOM自助选型
        if (this.isShowBomBtn) {
          const bomInitRes = await this.saveBomInit(params)
          if (!bomInitRes) return false
          return { id: bomInitRes.one_stop_temp_id, bomId: bomInitRes.bom_self_id }
        }

        return await saveOrderTemp(params)
          .then(res => {
            if (!res) return false
            const { retCode, result, retMsg } = res
            if (retCode === 0) {
              return { id: result, bomId: params.order_info.bom_self_id }
            }
            this.$message.error(retMsg || '未知错误，请稍后重试')
            return false
          })
      } catch (error) {
        console.log('catch', error)
        return false
      }
    },
    // 初始化BOM自助选型 & 暂存SMT
    async saveBomInit(priceJson = {}) {
      try {
        const { file_pcb, file_bom, file_patch, number, single_or_double_technique, custom_pcb_ban, pcbParams, order_info } = this.smtParams
        const params = {
          // PCB文件
          pcbFileUrl: file_pcb,
          // BOM文件
          bomFileUrl: file_bom,
          // SMT坐标文件
          coordinateFileUrl: file_patch,
          // SMT生产数量
          number: number,
          // 贴装面
          mountingSurfaceType: single_or_double_technique,
          // PCB订单号
          orderId: custom_pcb_ban == 2 && pcbParams.order_id ? pcbParams.order_id : '',
          // BOM自助匹配选型id
          oneStopBomId: order_info.bom_self_id || 0,
          // 要保存的SMT临时参数
          priceJson
        }

        return await bomInitialization(params)
          .then(async (res) => {
            if (!res) return false
            const { retCode, result, retMsg, errInfo } = res || {}
            if (retCode === 0 && result) {
              return result
            } else if (retCode === 510005) {
              // 【贴片面】是否选择错误提示
              this.changeBomParams(retMsg, JSON.parse(errInfo || '{}'))
              return false
            } else {
              this.$message.error(retMsg || '未知错误，请稍后重试')
              return false
            }
          })
      } catch (error) {
        return false
      }
    },
    // 初始化BOM自助选型 贴装面错误提示
    changeBomParams(tips, data) {
      this.$confirm(tips, {
          center: true,
          showCancelButton: false,
          confirmButtonText: '好的，继续提交',
        })
        .then(() => {
          this.smtParams = Object.assign(this.smtParams, data)
          this.handleSave()
        })
        .catch(() => {
          this.smtParams = Object.assign(this.smtParams, data)
        })
    },
    // 只下PCB
    async handleOnlyPcb() {
      try {
        this.onlyPcbLoading = true
        const res = await this.savePcbQuote({ isOnlyPcb: true })
        this.onlyPcbLoading = false
        if (!res) return
        const type = this.type.includes('FPC') ? 'FPC' : 'PCB'
        const query = { type: ORDER_TYPE_ENUMS[type], id: res.id, source: 'quote' }
        // SMT站点
        if (isSmtSite) {
          this.$router.push({ path: '/quote/submit', query })
        } else {
          // 用户中心站点
          const path = type === 'FPC' ? '/orders/fpc/submit' : '/orders/pcb/submit'
          targetSelfWindow({ path, query })
        }
      } catch (error) {
        this.onlyPcbLoading = false
      }
    },
    // 确认是否只下PCB弹框
    async confirmOnlyPcb() {
      return new Promise(resolve => {
        this.$confirm('请再次确认是否只需要下PCB单', '温馨提示', {
          center: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
        .then(() => resolve(true))
        .catch(() => resolve(false))
      })
    },

    // PCB站点，登录后直接提交PCB参数
    async savePcbParams(type, params) {
      try {
        const res = await this.savePcbQuote({ params })
        if (!res) return
        // PCB站点
        this.$router.push({
          path: this.submitPath,
          query: { type: ORDER_TYPE_ENUMS[type], id: res.id }
        })
      } catch (error) {
        console.log(error)
      }
    },
    // 登录弹框关闭
    hanleLoginDialogClose() {
      // 删除暂存的PCB提交参数
      sessionStorage.removeItem(BEFORE_LOGIN_PCB_PARAMS_KEY)
    }
  }
}
</script>

<style lang="scss" scoped>
.quote-right {
  .quote-right__formItem {
    margin-bottom: 10px;
    ::v-deep {
      .base-form-item__content {
        width: 100%;
      }
      .base-form-item__other {
        margin-left: 0;
      }
    }
  }
  .quote-right__submit {
    text-align: right;
    .quote-right__submit__price {
      margin: 10px 8px 4px 0;
      font-size: 13px;
      color: #111111;
      font-weight: 700;
      >span {
        color: $--color-primary;
        &:first-child {
          font-size: 15px;
          font-weight: 400;
        }
        &:last-child {
          font-size: 20px;
        }
      }
    }
    .quote-right__submit__tips {
      margin-bottom: 8px;
      font-size: 12px;
      color: $--color-text-disabled;
      >span {
        color: $--color-warning;
      }
    }
    .quote-right__submit__deliveryTips {
      margin-bottom: 8px;
      font-size: 12px;
      color: $--color-primary;
    }
    .quote-right__submit__btns {
      display: flex;
      justify-content: flex-end;
      .quote-right__submit__btn {
        padding: 11px 23px 12px;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: 1px;
        &.only-pcb {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>