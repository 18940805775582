<!-- PCB 生产周期选项 -->
<template>
  <RadioTable
    v-model="val"
    radioKey="name"
    radioLabel="#"
    :columns="columns"
    :tableData="options"
    @change="handleChange"
  >
    <template v-slot:color="{ row }">
      <span class="choose-deltime__color">
        <ColorBlock :color="row.color" showLabel />
      </span>
    </template>
    <template slot="empty">
      暂无交期
    </template>
  </RadioTable>
</template>

<script>
import RadioTable from '@@/components/radioTable/index'
import ColorBlock from '@@/components/colorBlock/index'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  components: {
    RadioTable,
    ColorBlock
  },
  data() {
    return {
      val: this.value,
      columns: [
        { label: '交期', prop: 'name', minWidth: 100 },
        { label: '油墨', prop: 'color', slot: 'color', headerSlot: 'colorHeader' },
        { label: '板厚', prop: 'bheight', width: 60 },
        { label: '加急费', prop: 'fee', formatter: row => row.fee > 0 ? `￥${row.fee}` : '无' },
      ]
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    colorTips(newVal) {
      this.colorTips = newVal
    }
  },
  methods: {
    handleEmit() {
      // this.$emit('input', this.val)
      this.$emit('change', this.val)
    },
    handleChange(item) {
      const { value, val, options } = this
      if (value == item.name) return
      
      const currentItem = options.find(i => i.name === value)
      let tips = []
      if (currentItem.color != item.color) {
        tips.push(`油墨颜色由【${currentItem.color}】调整为【${item.color}】`)
      }
      if (currentItem.bheight != item.bheight) { 
        tips.push(`板厚由【${currentItem.bheight}mm】调整为【${item.bheight}mm】`)
      }
      if (tips.length > 0) {
        this.$confirm(`您修改交期为 ${item.name}，我们需将${tips.join('，')}，请确认是否修改？`, '温馨提示', {
          confirmButtonText: '确定修改',
          cancelButtonText: '取消修改',
          lockScroll: false,
          center: true,
          dangerouslyUseHTMLString: true,
          closeOnClickModal: false,
          closeOnPressEscape: true
        })
        .then(() => {
          this.val = item.name
          this.handleEmit()
        })
        .catch(() => {
          this.val = value
          this.handleEmit()
        })
      } else {
        this.val = item.name
        this.handleEmit()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.choose-deltime__color {
  display: flex;
  align-items: center;
}
</style>