<!-- 华秋贴片优势弹框 -->
<template>
  <div>
    <BaseTextButton @click="isShow = true">华秋贴片优势</BaseTextButton>

    <el-dialog 
      class="is-need-smt-dialog"
      width="970px" 
      height="522px"
      title="华秋贴片优势"
      append-to-body
      :lock-scroll="false" 
      :visible="isShow"
      @close="isShow = false"
    >
      <div class="is-need-smt-dialog__content">
        <div v-for="(item, index) in list" :key="index"  class="is-need-smt-dialog__list">
          <img :src="item.img" />
          <p>{{ item.title }}</p>
          <ul>
            <li v-for="desc in item.desc" :key="desc" v-html="desc"></li>
          </ul>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      list: [
        {
          img: require('@@/assets/img/pcb/is_need_smt_1.png'),
          title: '工厂实力',
          desc: [
            '4大PCB/SMT生产基地',
            '15000m²SMT/PCBA贴片工厂',
            '36条全新西门子E系列SMT生产线',
            '全线AOI、2D/3D SPI/X-ray高端品质检验设备'
          ]
        },
        {
          img: require('@@/assets/img/pcb/is_need_smt_2.png'),
          title: '生产能力',
          desc: [
            '1片起贴、硬板和软板均可贴，无工艺限制',
            '可贴最小封装：01005',
            '可贴IC最小pin间距：0.25mm<br/>BGA最小球径间距：0.25mm',
            '贴片精度：chip类±41μm，IC类±37.5μm'
          ]
        },
        {
          img: require('@@/assets/img/pcb/is_need_smt_3.png'),
          title: '高性价比',
          desc: [
            '3000+原厂品牌，20万+自营现货SKU',
            '代购BOM物料，最快2小时配整单，8小时贴好发货',
            '批量焊点费低至0.006元/焊点',
            '自有PCB/SMT工厂，一站式PCBA全流程服务'
          ]
        },
      ]
    }
  }
}
</script>

<style lang="scss">
.is-need-smt-dialog {
  .el-dialog__header,
  .el-dialog__body {
    background-color: #f8f8f8;
  }
  .el-dialog__title {
    font-size: 16px;
    font-weight: 700;
    color: $--color-text-primary; 
  }
  .el-dialog__body {
    padding: 20px;
  }

  .is-need-smt-dialog__content {
    display: flex;
    justify-content: space-between;
    >div {
      padding: 20px 0 30px;
      width: 33%;
      border-radius: 10px;
      background-color: #fff;
      text-align: center;
      >p {
        margin: 20px 0 30px;
        font-size: 16px;
        font-weight: 700;
        color: $--color-text-primary;
      }
      >ul {
        text-align: left;
        >li {
          list-style: disc;
          margin-left: 30px;
          margin-bottom: 14px;
          padding-right: 10px;
          font-size: 14px;
          font-weight: 400;
          color: $--color-text-secondary;
          &::marker {
            color: $--color-primary;
          }
        }
      }
    }
  }
}
</style>