<!-- PCB -->
<template>
  <BaseFormItem
    class="custom_pcb_ban"
    :label="fields.label"
    :labelTips="fields.labelTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio
        border
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
      >
        {{ item.label }}
        <span v-if="item.desc" class="custom_pcb_ban__desc">
          {{ item.desc }}
        </span>
      </el-radio>
    </BaseRadioGroup>
    <!-- 上传、解析PCB文件 -->
    <div v-if="[1, 3].includes(val)" class="custom_pcb_ban__upload">
      <i :class="['custom_pcb_ban__list__linker', `linker_${val}`]"></i>
      <UploadAndAnalyzePcbfile 
        ref="uploadAndAnalyzePcbfileRef"
        v-model="pcbFile" 
        :type="`smt_${val}`"
        @change="(val, name) => handleFileChange(val, name)" 
        @analyzeSuccess="data => $emit('analyzeChange', data || {})" 
      />
    </div>
    <!-- PCB订单列表 -->
    <div v-if="val === 2" class="custom_pcb_ban__list">
      <i class="custom_pcb_ban__list__linker"></i>
      <PcbList :item="currentItem" @change="handleItemChange" />
    </div>
  </BaseFormItem>
</template>

<script>
import PcbList from '@@/components/pcbList/index'
import UploadAndAnalyzePcbfile from '@@/components/uploadAndAnalyzePcbfile/index'

import { getSMTFields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    item: {
      type: Object,
      default: () => ({})
    },
    // PCB文件
    filePcb: {
      type: String,
      default: ''
    }
  },
  components: {
    PcbList,
    UploadAndAnalyzePcbfile
  },
  data() {
    return {
      val: this.value,
      pcbFile: this.filePcb,
      currentItem: {},
      onceTips: true, // 自已提供上传提示，仅提示一次
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getSMTFields('custom_pcb_ban')
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    item(item) {
      this.currentItem = item
    },
    filePcb(newVal) {
      this.pcbFile = newVal
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
      this.isShowError(false)
    },
    handleEmitChange() {
      const { val, currentItem } = this
      this.$emit('change', val, val === 2 ? currentItem : {})
      this.isShowError(false)
    },
    handleItemChange(item) {
      this.currentItem = Object.assign({}, item)
      this.handleEmitChange()
    },
    handleFileChange(val, name) {
      if (this.val == 3 && this.onceTips && val) {
        this.$confirm('为确保贴片的准确性，请再次确认上传的文件是否为PCB厂的最新生产文件', '温馨提示', {
          confirmButtonText: '是的',
          cancelButtonText: '不是，重新上传',
          lockScroll: false,
          center: true,
          dangerouslyUseHTMLString: true,
          closeOnClickModal: false,
          closeOnPressEscape: true
        }).then(() => {
          this.$emit('fileChange', val, name)
        }).catch(() => {
          this.$refs.uploadAndAnalyzePcbfileRef.handleReset()
        })
        this.onceTips = false
      } else {
        this.$emit('fileChange', val, name)
      }
      this.isShowError(false)
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@@/styles/mixin.scss';

.custom_pcb_ban {
  .custom_pcb_ban__desc {
    color: $--color-text-placeholder;
  }

  .custom_pcb_ban__upload {
    position: relative;
    margin-top: 5px;
    .custom_pcb_ban__list__linker {
      @include moduleLinkerMixin(15px, vertical, dark);
      position: absolute;
      top: -10px;
      left: 8px;
      &.linker_3 {
        left: 324px;
      }
    }
  }

  .custom_pcb_ban__list {
    position: relative;
    margin-top: 5px;
    .custom_pcb_ban__list__linker {
      @include moduleLinkerMixin(15px, vertical, dark);
      position: absolute;
      top: -10px;
      left: 114px;
    }
  }
}
</style>