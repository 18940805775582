<template>
  <!-- PCB FPC 下单必看提示 -->
  <div v-if="isShow" class="must-see-tips">
    <span class="must-see-tips__label">温馨提示</span>
    <span class="must-see-tips__text">
      请仔细填写PCB相关参数，并确保与相关文件参数一致；PCB生产将以下单参数为准
    </span>
    <BaseLink href="https://www.hqpcb.com/help/c_59.html" target="_blank">
      下单必看
      <i class="el-icon-d-arrow-right" />
    </BaseLink>
    <el-divider direction="vertical" />
    <BaseTextButton @click="handleClick">
      我知道了
    </BaseTextButton>
  </div>
</template>

<script>
// 隐藏下单必看的浏览器缓存key
const IS_SHOW_MUST_SEE_TIPS_KEY = 'IS_SHOW_MUST_SEE_TIPS_KEY'

export default {
  data() {
    return {
      isShow: true
    }
  },
  created() {
    if (localStorage.getItem(IS_SHOW_MUST_SEE_TIPS_KEY) === 'hide') {
      this.handleClick()
    }
  },
  methods: {
    handleClick() {
      this.isShow = false
      this.$emit('hide')
      localStorage.setItem(IS_SHOW_MUST_SEE_TIPS_KEY, 'hide')
    }
  }
}
</script>

<style lang="scss" scoped>
.must-see-tips {
  margin: 5px 0 10px 15px;
  font-size: $--font-size-small;
  .must-see-tips__label {
    margin-right: 10px;
    padding: 2px 10px;
    color: $--tips-color;
    border-radius: $--border-radius-base;
    background-color: $--tips-background-color;
  }
  .must-see-tips__text {
    margin-right: 6px;
    color: #7F7F7F;
  }
}
</style>
