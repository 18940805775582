<!-- PCB专属客服浮窗 -->
<template>
  <div class="affix-service">
    <el-popover
      placement="left-start"
      width="200"
      trigger="hover"
      popper-class="affix-service__popper service"
    >
      <div v-if="serviceData" class="affix-service__service">
        <p>我的专属客户经理：</p>
        <span>{{ serviceData.nickname || '' }} {{ serviceData.tel || '' }}</span>
        <div v-if="serviceData.qrcode_full">
          <span>微信咨询</span>
          <img :src="serviceData.qrcode_full" />
        </div>
      </div>
      <div v-else class="affix-service__phone">
        <p>联系我们：</p>
        <div>
          <i class="el-icon-phone"></i>
          <span>0755-83688678</span>
        </div>
        <p>工作时间：</p>
        <span>
          周一至周五（9:00-12:00，13:30-18:30）节假日除外
        </span>
      </div>
      <div slot="reference" class="affix-service__item" title="通过电话联系客户经理">
        <i class="affix-service__icon service-icon"></i>
      </div>
    </el-popover>
    
    <el-popover
      placement="left-start"
      width="150"
      trigger="hover"
      popper-class="affix-service__popper qrcode"
    >
      <div class="affix-service__qrcode__content">
        <p>关注华秋电路官方微信</p>
        <img src="@@/assets/img/pcb/hqpcb_gzh.jpg" alt="华秋电路微信公众账号">
        <p>实时查看最新订单进度</p>
      </div>
      <div slot="reference" class="affix-service__item" title="微信公众号">
        <i class="affix-service__icon qrcode-icon"></i>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { getPcbService } from '@@/api/pcb'
export default {
  data() {
    return {
      serviceData: null
    }
  },
  mounted() {
    getPcbService()
      .then(res => {
        if (!res) return
        this.serviceData = res.result || null
      })
  }
}
</script>

<style lang="scss" scoped>
.affix-service {
  position: fixed;
  right: 0;
  bottom: 50%;
  margin-bottom: -80px;
  .affix-service__item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1px;
    width: 48px;
    height: 48px;
    border: 1px solid #dedede;
    background-color: #fff;
    &:hover {
      background-color: $--form-color-base;
      .service-icon {
        background: url('~@@/assets/img/icons/icon_service_hover.png');
      }
      .qrcode-icon {
        background: url('~@@/assets/img/icons/icon_qrcode_hover.png');
      }
    }
    .affix-service__icon {
      display: block;
      width: 25px;
      height: 25px;
    }
    .service-icon {
      background: url('~@@/assets/img/icons/icon_service.png');
    }
    .qrcode-icon {
      background: url('~@@/assets/img/icons/icon_qrcode.png');
    }
  }
}

.affix-service__service {
  font-size: 12px;
  >p {
    margin-bottom: 6px;
    font-weight: 700;
    color: $--color-text-primary;
  }
  >span {
    color: #5f5f5f;
  }
  >div {
    margin-top: 10px;
    padding: 0 10px 10px;
    text-align: center;
    border-radius: 4px;
    border: 1px dashed #d7d7d7;
    >span {
      display: block;
      margin: 6px 0;
    }
    >img {
      display: block;
      margin: 0 auto;
      max-width: 120px;
      max-height: 120px;
      object-fit: contain;
    }
  }
}

.affix-service__phone {
  color: $--color-text-primary;
  font-size: 12px;
  >p {
    margin-bottom: 6px;
    font-weight: 700;
  }
  >div {
    margin-bottom: 15px;
    >i {
      margin-right: 4px;
      font-size: 18px;
      color: $--form-color-base;
    }
    >span {
      font-size: 16px;
    }
  }
  >span {
    color: #5f5f5f;
  }
}

.affix-service__qrcode__content {
  >p {
    font-size: 12px;
    color: $--color-text-secondary;
    text-align: center;
  }
  >img {
    display: block;
    margin: 5px auto;
  }
}
</style>

<style lang="scss">
.affix-service__popper.service {
  box-sizing: border-box;
  padding: 16px 24px;
}
.affix-service__popper.qrcode {
  box-sizing: border-box;
  padding: 10px 13px;
}
</style>