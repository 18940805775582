
/**
 * 锚点导航
 * @param {number} top - 偏移距离 默认160
 * @param {object} key - 模块id value - 导航名称
 * 
 * v-anchorNav:top={anchorId: anchorName}
 */
export const anchorNav = {
  bind: function(el, binding) {
    const defaultTop = '340'
    binding.listEl = []
    binding.def.ulEl = null
    // 滚动事件
    binding.def.scrollHandle = () => {
      const elRect = el.getBoundingClientRect()
      const ulRect = binding.def.ulEl.getBoundingClientRect()
      binding.def.ulEl.style.top = `${Math.min(elRect.bottom - ulRect.height, defaultTop)}px`

      binding.listEl.forEach(({ liEl }) => liEl.classList.remove('active'))
      // 视图内的第一个模块显示激活状态
      const currentFirstEl = binding.listEl.find(({ targetEl }) => targetEl.getBoundingClientRect().top >= 0)
      currentFirstEl && currentFirstEl.liEl.classList.add('active')
    }
    // 浏览器窗口大小变化
    binding.def.resizeHandle = () => {
      const { left, right } = el.getBoundingClientRect()
      binding.def.ulEl.style.left = `${binding.modifiers.right ? right + 20 : left - 91}px`
    }
  },

  inserted: function(el, binding, vnode) {
    const top = binding.arg || '340'
    const list = binding.value
    const that = vnode.context

    const createElement = target => {
      const element = document.createElement(target)
      if (target === 'ul') {
        const { left, right } = el.getBoundingClientRect()
        element.classList.add('anchor-nav-directives')
        element.style.cssText = `
          top: ${top}px; 
          left: ${binding.modifiers.right ? right + 20 : left - 91}px;
        `
      }
      return element
    }

    that.$nextTick(() => {
      binding.def.ulEl = createElement('ul')

      Object.keys(list).forEach(id => {
        const targetEl = document.getElementById(id)
        if (targetEl) {
          const liEl = createElement('li')
          const text = document.createTextNode(list[id])
          liEl.appendChild(text)

          liEl.addEventListener('click', () => {
            targetEl.scrollIntoView({ behavior: 'smooth' })
            binding.def.scrollHandle()
          })
          
          binding.def.ulEl.appendChild(liEl)
          binding.listEl.push({ targetEl, liEl })
        }
      })

      el.appendChild(binding.def.ulEl)
      binding.def.scrollHandle()
      // 监听滚动事件
      window.addEventListener("scroll", binding.def.scrollHandle, true)
      // 监听浏览器窗口大小变化
      window.addEventListener('resize', binding.def.resizeHandle, true)
    })
    
  },

  unbind(el, binding) {
    el.removeChild(binding.def.ulEl)
    document.removeEventListener("scroll", binding.def.scrollHandle, true)
    document.removeEventListener("resize", binding.def.resizeHandle, true)
  }
}
