<!-- SMT生产周期必看弹框 -->
<template>
  <div class="smt-deltime-mustsee">
    <BaseTextButton @click="isShow = true">
      SMT生产周期必看
    </BaseTextButton>

    <el-dialog 
      width="600px" 
      top="100px" 
      title="生产周期说明" 
      class="smt-deltime-dialog" 
      append-to-body
      :close-on-click-modal="false"
      :lock-scroll="false"
      :visible="isShow"
      @close="isShow = false"
    >
      <div>
        <div class="smt-deltime-dialog__item">
          <h3 >物料齐套定义</h3>
          <p>· 物料齐料后经我司QC入库分拣和元器件焊盘核对且确认无误后，开始计算<span>生产周期</span>（QC入库分拣与核对焊盘时效<span>1-2</span>天）</p>
        </div>
        <div class="smt-deltime-dialog__item">
          <h3>订单生产周期定义</h3>
          <p>· 焊盘核对OK时间在<span>16点之前</span>的，以焊盘核对ok时间开始计算；焊盘核对OK时间在<span>16点之后</span>的，从<span>次日8点</span>开始计算交期</p>
        </div>
        <div class="smt-deltime-dialog__item">
          <h3>样品图时效定义</h3>
          <p>·  如需确认样品图, 请务必在<span>4小时内</span>确认完毕，否则交期将根据样品图确认好的时间延迟交期顺延，且<span>16点之前</span>样品图确认OK，以样板确认OK时间为准开始计算交期；<span>16点之后</span>样品图确认OK的，从次日8点开始计算交期</p>
        </div>
        <div class="smt-deltime-dialog__item">
          <h3>订单资料或问题确认定义</h3>
          <p>· 订单资料确认完整需在物料齐套前48小时内核对完成（如齐料后更新文件或确认工程问题，延迟交期将顺延）</p>
        </div>
        <div class="smt-deltime-dialog__item">
          <h3>加急单周期定义</h3>
          <p>· 如遇加急订单有特殊制程的将根据双方达成一致上线时间执行，加急时效按如下“加急单生产周期”推进，特殊情况以沟通意见执行</p>
        </div>
        <div class="smt-deltime-dialog__item">
          <h3>目标生产周期定义</h3>
          <p>· 以<span>工作日</span>为单位，周日和法定节假日工厂不生产，如遇集中停电或疫情等特殊因素将提前通知客户并祥见我司公告</p>
        </div>
        <div class="smt-deltime-dialog__item">
          <p>以上生产目标为标准工艺SMT+DIP+包装，如需开立DIP焊接治具、压接治具、涂三防漆、烧录、功能测试、组装等特殊工艺情况，根据工艺评估增加生产周期（开立治具需<span>1-2</span>个工作日）<br/><span>另：若大于2000套或插件总焊点数大于100000点，请联系您的客服为您提供准确的交期</span></p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false
    }
  }
}
</script>

<style lang="scss" scoped>
.smt-deltime-mustsee {
  .base-text-button:hover {
    background-color: #fff;
  }
}
.smt-deltime-dialog ::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid $--border-color-base;
    height:30px;
    line-height:30px;
    text-align: center;
  }
  .el-dialog__body {
    padding: 20px 50px 30px;
  }
  .el-dialog__title {
    font-size: 16px;
    font-weight: 700;
    color: $--color-text-primary;
  }

  .smt-deltime-dialog__item {
    margin-bottom: 20px;
    h3 {
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 8px;
    }
    p {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      >span {
        color: $--color-primary;
      }
    }
  }
}
</style>