<!-- 制板费明细 -->
<template>
  <div class="price-details">
    <div 
      v-for="(data, type) in list" 
      :key="type" 
      :class="{ 'price-details__content':true, 'multicolumn': col > 1 }"
    >
      <p class="quote-container__right__title">
        {{ type === 'SMT' ? 'SMT费用明细' : 'PCB费用明细' }}
      </p>
      <ViewList
        :class="{ 'fpc-tips': isFpcNeedAudit && type === 'FPC' }"
        prefix="￥"
        :emptyText="isFpcNeedAudit && type === 'FPC' ? `软硬结合板暂不支持自动计价\n具体价格以审核为准` : '还未有价格，请填写具体参数'"
        :col="col === 2 ? 1 : 2"
        :data="data"
      />
    </div>
  </div>
</template>

<script>
// 列表展示
import ViewList from '@@/components/viewList/index'
import { PRICE_FIELDS } from '@@/utils/constant'

export default {
  props: {
    type: {
      type: String,
      validator: function (value) {
        return ['PCB', 'FPC', 'SMT', 'PCB_SMT', 'FPC_SMT', 'SMT_PCB', 'SMT_FPC'].includes(value)
      }
    },
    pcbPrice: {
      type: Object,
      default: () => ({})
    },
    smtPrice: {
      type: Object,
      default: () => ({})
    },
    // 表面处理
    spray: {
      type: String,
      default: ''
    },
    isFpcNeedAudit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ViewList
  },
  computed: {
    col() {
      return this.type.split('_').length
    },
    list() {
      const { type, isFpcNeedAudit } = this
      const types = type.split('_')
      // PCB/FPC排前面
      return ['PCB', 'FPC', 'SMT']
        .filter(i => types.includes(i))
        .reduce((data, type) => {
          data[type] = isFpcNeedAudit && type === 'FPC' ? [] : this.getList(type)
          return data
        }, {})
    }
  },
  methods: {
    getList(type) {
      const { pcbPrice, smtPrice, spray } = this
      const data = type === 'SMT' ? smtPrice : pcbPrice
      let fields = PRICE_FIELDS[type]

      // PCBA订单的PCB费用字段
      if (this.type.includes('SMT') && ['PCB', 'FPC'].includes(type)) {
        fields = Object.assign(fields, {
          insurancefee: 'PCB品质服务费',
          invoicefee: '税费',
          expressage: '运费',
          discount_amount: '活动折扣',
        })
      }

      return Object.keys(fields)
        .filter(key => data[key] && data[key] > 0)
        .map(key => {
          let label = fields[key]
          if (key === 'sprayfee') {
            label = `${spray === 'OSP' ? 'OSP' : spray.substring(0, 2)}费`
          }
          let prefix
          // discount_amount - PCB活动折扣
          // activity_discount_fee - SMT活动折扣
          if (['activity_discount_fee', 'discount_amount'].includes(key)) {
            prefix = '-￥'
          }
          return { label, value:  data[key], prefix }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.price-details {
  display: flex;
  >div + div {
    margin-left: 6px;
  }
  .price-details__content {
    flex: 1;
    &.multicolumn {
      width: calc(50% - 3px);
      .view-list {
        min-height: calc(100% - 35px);
        background-color: $--background-base;
      }
    }
  }
  .fpc-tips {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}
</style>