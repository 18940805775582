<!-- 3D解析 -->
<template>
  <div :class="`analyze3D analyze3D--${status}`">
    <!-- 解析中 -->
    <template v-if="status === 0">
      <i class="el-icon-loading"></i>
      3D仿真图加载中
    </template>
    <!-- 解析成功 -->
    <template v-if="status === 1">
      <i class="el-icon-circle-check"></i>
      3D解析图加载成功
    </template>
    <!-- 解析失败 -->
    <template v-if="status === 2">
      <i class="el-icon-circle-close"></i>
      3D解析图加载失败
    </template>
  </div>
</template>

<script>
import { file3DAnalyzeResult } from '@@/api/pcb'

// 解析
const defaultAnalyze = {
  analyzeing: false, //解析中
  analyzeTimer: null, // 解析接口定时器
}

export default {
  props: {
    analyzeId: {
      tyep: String,
      default: ''
    },
    analyzeKey: {
      tyep: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      status: 0, // 状态： 0-解析中, 1-解析成功, 2-解析失败
      analyze: { ...defaultAnalyze }
    }
  },
  watch: {
    analyzeId(newVal) {
      this.analyze.analyzeTimer && clearInterval(this.analyze.analyzeTimer)

      if (newVal) {
        this.startAnalyze()
      }
    },
    analyzeKey(newVal) {
      console.log('analyzeKey', newVal)
      this.analyze.analyzeTimer && clearInterval(this.analyze.analyzeTimer)

      if (newVal || newVal === 0) {
        this.startAnalyze()
      }
    }
  },
  mounted() {
    if (this.analyzeId) {
      this.startAnalyze()
    }
  },
  methods: {
    handleEmit(isSuccess) {
      this.$emit('complete', isSuccess)
    },
    // 文件解析
    startAnalyze() {
      this.status = 0
      this.$emit('analyzeing', true)
      this.analyze.analyzeing = true
      const params = { analyze_id: this.analyzeId, key: this.analyzeKey }
      this.analyze.analyzeTimer = setInterval(() => {
        file3DAnalyzeResult(params)
          .then(res => {
            if (!res) return
            const { code } = res
            // code: 200-解析成功 105-解析中  其余的都算失败
            if (code == 200) {
              this.analyzeSuccess()
            } else if (code != 105) {
              this.analyzeFailed()
            }
          })
          .catch(err => {})
      }, 1500)
    },
    // 解析完成：1-解析成功 2-解析失败
    endAnalyze(status) {
      clearInterval(this.analyze.analyzeTimer)
      this.analyze.analyzeing = false
      const timer = setTimeout(() => {
        this.status = status
        clearTimeout(timer)
      }, 500)
    },
    // 解析成功
    analyzeSuccess() {
      this.endAnalyze(1)
      this.handleEmit(true)
    },
    // 解析失败
    analyzeFailed() {
      this.endAnalyze(2)
      this.handleEmit(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.analyze3D {
  padding-top: 16px;
  >i {
    color: $--color-success;
  }
  &.analyze3D--1 {
    >i {
      color: $--color-success;
    }
  }
  &.analyze3D--2 {
    >i {
      color: $--color-danger;
    }
  }
}
</style>