<!-- 样品图确认 -->
<template>
  <BaseFormItem
    :label="fields.label"
    :labelTips="fields.labelTips"
    isShowRequiredIcon
    :fillTips="fillTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <BaseTipPopover 
        v-for="item in fields.options"
        :key="item.value" 
        :content="item.tips" 
        :disabled="!item.tips"
      >
        <el-radio-button
          slot="reference"
          :label="item.value"
        >{{ item.label }}</el-radio-button>
      </BaseTipPopover>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getSMTFields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    // 物料烘烤
    is_material_baking: {
      type: Number,
      default: 0
    },
    // 板面清洁
    is_layout_cleaning: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      val: this.value,
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getSMTFields('is_first_confirm')
    },
    // 是否显示未填写样式
    fillTips() {
      const val = this.val
      return !val && val !== 0 ? '请选择' : ''
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    is_material_baking(newVal) {
      this.handleChangeVal(newVal)
    },
    is_layout_cleaning(newVal) {
      this.handleChangeVal(newVal)
    },
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
      this.isShowError(false)
    },
    // 需要 物料烘烤/板面清洁 时，样品图确认 选中 需要
    handleChangeVal(newVal) {
      if (newVal === 1 && this.val !== 1) {
        this.val = 1
        this.handleEmit()
      }
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss">
</style>