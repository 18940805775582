<!-- 包装类型 -->
<template>
  <BaseFormItem
    :label="fields.label"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
        :disabled="disabledOption2 && item.value == 2"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getSMTFields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: Number,
      default: 1
    },
    // 拆板出货
    need_split: {
      type: Number,
      default: 1
    },
    // PCB拼板尺寸
    pcbSize: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getSMTFields('packing_type')
    },
    // 是否禁用 静电袋+刀卡 选项
    disabledOption2() {
      const { need_split, pcbSize, val } = this
      if (need_split !== 0) return false
      const { width, length } = pcbSize
      // 当 不拆板出货 时，且 pcb拼版尺寸 长边大于40cm或短边大于30cm时，禁用 静电袋+刀卡
      const [longSide, shortSide] = [width, length].sort((a,b) => b - a)
      const isDisabled = longSide > 40 || shortSide > 30

      if (isDisabled && val == 2) {
        this.val = 1
        this.handleAlertTips()
        this.handleEmit()
      }

      return isDisabled
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
    },
    handleAlertTips() {
      this.$alert('因PCBA出货尺寸超30*40cm，包装类型已发生了变化，请仔细核对', '温馨提示', {
        confirmButtonText: '确定',
        lockScroll: false,
        center: true,
        customClass: 'alert-fields-tips',
        closeOnPressEscape: true
      })
    }
  }
}
</script>

<style lang="scss">
</style>