<!-- PCBA 总费用明细 -->
<template>
  <div class="price-total-view">
    <div class="price-view">
      <ul>
        <!-- PCB制作费 -->
        <li v-if="showPcb">
          <span class="price-view__label">
            PCB总费用：
          </span>
          <span class="price-view__value" :class="{'has-active': !!pcbDiscountAmount}">
            <span v-if="isFpcNeedAudit && ['FPC_SMT', 'SMT_FPC'].includes(type)" style="color: #E6A23C">
              以审核为准
            </span>
            <template v-else>
              ￥{{ pcbAmount }}
            </template>
            <span v-if="pcbDiscountAmount" class="has-active__tips">
              {{ pcbDiscountAmount }}
            </span>
          </span>
        </li>
        <!-- SMT加工费 -->
        <li>
          <span class="price-view__label">
            SMT总费用：
          </span>
          <span class="price-view__value" :class="{'has-active': !!smtSpecialOfferBoard}">
            ￥{{ smtAmount }}
            <span v-if="smtSpecialOfferBoard" class="has-active__tips">
              {{ smtSpecialOfferBoard }}
            </span>
          </span>
        </li>
        <!-- BOM物料费 -->
        <li v-if="[1, 4].includes(+buy_bom) && [0, 1, 2].includes(modelSelectionType)">
          <span class="price-view__label">
            BOM物料费：
          </span>
          <!-- 华秋帮我选型 -->
          <template v-if="buy_bom == 1">
            <span class="price-view__value" style="color: #aaa;">
              待人工报价
            </span>
          </template>
          <!-- 自助匹配选型 -->
          <template v-else>
            <span v-if="modelSelectionType === 0" class="price-view__value" style="color: #aaa;">
              待匹配
            </span>
            <template v-else>
              <span class="price-view__value">
                {{ modelSelectionType === 2 ? '待人工报价' : `￥${data.bom_amount}` }}
              </span>
              <div v-if="modelSelectionType === 2" class="price-view__desc">
                <span>
                  已配器件：<span>￥{{ data.bom_amount }}</span>，待询价/代配器件：<span>待人工报价</span>
                </span>
              </div>
            </template>
          </template>
          
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// 订单价格展示
import PriceView from '@@/components/priceView/index'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      validator: function (value) {
        return ['PCB', 'FPC', 'SMT', 'PCB_SMT', 'FPC_SMT', 'SMT_PCB', 'SMT_FPC'].includes(value)
      }
    },
    isFpcNeedAudit: {
      type: Boolean,
      default: false
    },
    showPcb: {
      type: Boolean,
      default: false
    },
    buy_bom: {
      type: [Number, String],
      default: ''
    },
    modelSelectionType: {
      type: Number,
      default: 0
    }
  },
  components: {
    PriceView
  },
  computed: {
    pcbAmount() {
      try {
        const pcbPrice = (this.data.detail || {}).pcb || {}
        return (+(pcbPrice.total || 0)).toFixed(2)
      } catch (error) {
        return '0.00'
      }
    },
    // PCB活动折扣
    pcbDiscountAmount() {
      if (this.data.detail && this.data.detail.pcb) {
        const discount_amount = this.data.detail.pcb.discount_amount || 0
        return discount_amount > 0 ? `活动已减：-￥${discount_amount}` : ''
      }
      return ''
    },
    smtAmount() {
      let val = 0
      if (this.data.detail && this.data.detail.smt) {
        val = this.data.detail.smt.smt_order_fee || 0
      }
      return val.toFixed(2)
    },
    bomAmount() {
      return (+this.data.bom_amount || 0).toFixed(2)
    },
    // SMT特价板活动
    smtSpecialOfferBoard() {
      const smtActive = (this.data.activity || {}).smt || []
      // S10001 快板活动
      const activeItem = smtActive.find(item => item.code !== 'S10001')
      if (activeItem) {
        return `活动已减：-￥${activeItem.amount}`
      }
      return ''
    },
  }
}
</script>

<style lang="scss" scoped>
.price-total-view {
  margin-top: 12px;
}

.price-view {
  padding: 12px;
  border: 1px dashed $--border-color-base;
  border-radius: $--border-radius-base;
  >ul {
    line-height: 28px;
    text-align: right;
    >li {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
    }
  }
  .price-view__value {
    display: block;
    width: 100px;
    &.has-active {
      position: relative;
      margin-bottom: 20px;
      .has-active__tips {
        position: absolute;
        right: 0;
        top: 24px;
        display: inline-block;
        line-height: 1;
        padding: 4px;
        padding-left: 10px;
        font-size: 12px;
        white-space: nowrap;
        color: #FF8800;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background-color: #FEF5E4;
        &::after {
          content: '';
          position: absolute;
          top: -4px;
          right: 20px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 5px solid #FEF5E4;
        }
      }
    }
  }
  .price-view__desc {
    width: 100%;
    line-height: 20px;
    >span {
      padding: 2px 4px;
      font-size: 12px;
      border-radius: 2px;
      color: #888888;
      background-color: #F5F7FA;
      >span {
        color: #333333;
      }
    }
  }
}
</style>