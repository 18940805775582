<!-- 上传、解析PCB文件 -->
<template>
  <div class="pcbfile">
    <el-upload
      drag
      ref="uploadRefs"
      name='file'
      accept='.zip,.rar'
      class="pcbfile-upload"
      :class="{ 'pcbfile-upload--fail': status === 3, [`pcbfile-upload__analyze3D--${status}`]: isShowAnalyze3D, 'analyzeing': analyze.analyzeing }"
      :action="url"
      :show-file-list="false"
      :before-upload="handleBeforeUpload"
      :on-progress="handleOnProgress"
      :on-success="handleOnSuccess"
      :on-error="handleOnError"
    >
      <!-- 未开始 -->
      <div v-if="status === 0" class="pcbfile-upload__notStarted">
        <img :src="require('@@/assets/img/icons/icon_upload4.png')" />
        <div v-if="type === 'smt_3'">
          自己提供的PCB，请上传PCB厂的<i>最新生产文件</i>，以确保贴片的准确性
        </div>
        <div v-else>
          上传Gerber/PCB文件，<em>自动解析</em>计价参数
        </div>
        <p>zip, rar格式 (小于50MB)</p>
      </div>
      <!-- 上传中 -->
      <div v-if="status === 1" class="pcbfile-upload__uploading" @click.stop>
        <div class="pcbfile-upload__uploading__name">
          <p>{{ name }}</p>
          <span>({{ viewSize }})</span>
        </div>
        <p>上传中</p>
        <el-progress
          color="#15A675"
          text-color="#FFFFFF"
          define-back-color="#EBECEF"
          :stroke-width="18"
          :text-inside="true"
          :percentage="upload.progress"
        ></el-progress>
      </div>
      <!-- 上传成功 解析中 -->
      <div v-if="status === 2" class="pcbfile-upload__success" @click.stop>
        <div class="pcbfile-upload__success__name">
          <img :src="require('@@/assets/img/icons/icon_zip.png')" />
          <p>{{ name }}</p>
          <i class="el-icon-success"/>
        </div>
        <div class="pcbfile-upload__analyzeing">
          文件解析中
          <span>(预计{{ analyze.countdown }}秒后解析完成)</span>
        </div>
        <el-progress
          color="#579DE2"
          text-color="#FFFFFF"
          define-back-color="#EBECEF"
          :stroke-width="18"
          :text-inside="true"
          :percentage="analyze.progress * 100"
          :format="val => `${Math.floor(val)}%`"
        ></el-progress>
      </div>
      <!-- 上传失败 -->
      <div v-if="status === 3" class="pcbfile-upload__fail" @click.stop>
        <div class="pcbfile-upload__fail__text">
          <i class="el-icon-error"/>
          <p>上传失败</p>
        </div>
        <p>{{ errorMsg }}</p>
        <div class="pcbfile-upload__fail__btns">
          <el-button round @click="handleClick">重新上传</el-button>
          <el-button round type="text" class="pcbfile-upload__button" @click="handleReset">重置</el-button>
        </div>
      </div>
      <!-- 解析成功 -->
      <div v-if="status === 4" class="pcbfile-upload__analyze" @click.stop>
        <div 
          v-for="item in analyze.list" 
          :key="item.key"
          :class="{ 'pcbfile-upload__success__name': true, fail: item.status != 1, active: analyze.currentKey == item.key }"
          @click="handleAnalyzeChange(item.key)"
        >
          <img :src="require('@@/assets/img/icons/icon_zip.png')" />
          <p>{{ item.name }}</p>
        </div>
        <!-- 多文件切换文件解析中 -->
        <template v-if="analyze.analyzeing">
          <div class="pcbfile-upload__analyzeing">
            文件解析中
            <span>(预计{{ analyze.countdown }}秒后解析完成)</span>
          </div>
          <el-progress
            color="#579DE2"
            text-color="#FFFFFF"
            define-back-color="#EBECEF"
            :stroke-width="18"
            :text-inside="true"
            :percentage="analyze.progress * 100"
            :format="val => `${Math.floor(val)}%`"
          ></el-progress>
        </template>
        <!-- 解析完成 -->
        <template v-else>
          <!-- 有解析数据 -->
          <div v-if="analyze.result" class="pcbfile-upload__analyze__text">
            <p>
              <i class="el-icon-success"/>
              <em>解析成功</em>
              <span>相关参数已自动填充；为确保信息无误，请核对后下单</span>
            </p>
            <a :href="geberviewUrl" target="_blank">Gerber在线查看器</a>
          </div>
          <!-- 没解析数据 -->
          <div v-else class="pcbfile-upload__analyze__text fail">
            <p>
              <i class="el-icon-warning"/>
              <em>解析失败</em>
              <span>无法解析参数 (不影响下单)，请手动填写参数后下单 </span>
            </p>
          </div>
          <div class="pcbfile-upload__analyze__btns">
            <el-button round type="text" class="pcbfile-upload__button" @click="handleClick">重新上传</el-button>
            <el-button round type="text" class="pcbfile-upload__button" @click="handleReset">重置</el-button>
          </div>
        </template>
      </div>
      <!-- 解析失败 -->
      <div v-if="status === 5" class="pcbfile-upload__analyze" @click.stop>
        <div class="pcbfile-upload__success__name">
          <img :src="require('@@/assets/img/icons/icon_zip.png')" />
          <p>{{ name }}</p>
        </div>
        <div class="pcbfile-upload__analyze__text fail">
          <p>
            <i class="el-icon-warning"/>
            <em>解析失败</em>
            <span>无法解析参数 (不影响下单)，请手动填写参数后下单 </span>
          </p>
        </div>
        <div class="pcbfile-upload__analyze__btns">
          <el-button round type="text" class="pcbfile-upload__button" @click="handleClick">重新上传</el-button>
          <el-button round type="text" class="pcbfile-upload__button" @click="handleReset">重置</el-button>
        </div>
      </div>
      <!-- 修改回显 -->
      <div v-if="status === 6" class="pcbfile-upload__analyze" @click.stop>
        <div class="pcbfile-upload__success__name">
          <img :src="require('@@/assets/img/icons/icon_zip.png')" />
          <p>{{ name }}</p>
        </div>
        <div class="pcbfile-upload__analyze__btns">
          <el-button round type="text" class="pcbfile-upload__button" @click="handleClick">重新上传</el-button>
          <el-button round type="text" class="pcbfile-upload__button" @click="handleReset">重置</el-button>
        </div>
      </div>
      
      <!-- 3D解析 -->
      <div v-if="isShowAnalyze3D" class="pcbfile-upload__analyze3D">
        <Analyze3D 
          :analyzeId="analyze_id" 
          :analyzeKey="analyze.currentKey" 
          @analyzeing="isLoading => $emit('analyzeing3D', isLoading)"
          @complete="handleAnalyze3DComplete"
        />
      </div>
    </el-upload>
    <p v-if="status === 0 && type !== 'smt_3'" class="pcbfile-tips">
      暂不支持：CAD, Allegro, Gerber274-D, Protel98(及以下版本)的文件
    </p>
  </div>
</template>

<script>
import Analyze3D from './analyze3D.vue'

import { sensorsTrack } from '@@/utils/sensors'
import {
  HQPCB_HOST,
  fileAnalyzeResult
} from '@@/api/pcb'

// 上传
const defaultUpload = {
  progress: 0, // 上传进度百分比
  timer: null, // 上传进度百分比定时器
}
// 解析
const defaultAnalyze = {
  analyzeing: false, //解析中
  progress: 0, // 解析进度百分比
  timer: null, // 解析进度百分比定时器
  currentKey: 0, 
  list: [], // 解析成功列表
  analyzeTimer: null, // 解析接口定时器
  countdown: 60, // 解析倒计时
  countdownTimer: null, // 倒计时定时器
  timeConstant: 5000, // 进度条变化的快慢，数字越大，变化的越慢
  result: null, // 解析成功的数据
}

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'pcb'
    },
    // 是否需要解析
    needAnalyeze: {
      type: Boolean,
      default: true
    },
    // 是否需要解析3D
    needAnalyeze3D: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Analyze3D
  },
  data() {
    return {
      val: this.value,
      isShowAnalyze3D: false,
      url: `${HQPCB_HOST}/upfile?type=gerber&full_url=1`,
      status: 0, // 状态： 0-未开始, 1-上传中, 2-上传成功, 3-上传失败, 4-解析成功, 5-解析失败, 6-修改回显
      name: '', // 文件名称
      size: 0, // 单位B
      analyze_id: '', // 上传成功后返回的id
      errorMsg: '', // 上传失败提示
      upload: { ...defaultUpload },
      analyze: { ...defaultAnalyze }
    }
  },
  computed: {
    geberviewUrl() {
      const { analyze_id, analyze } = this
      return `${HQPCB_HOST}/gerberviewer/viewer?id=${analyze_id}&key=${analyze.currentKey}`
    },
    // 文件大小
    viewSize() {
      const size = this.size
      let text = size + ' B'
      if (size > 1024) {
        text = parseInt(size / 1024) + ' KB'
      }
      if (size > 1024 * 1024) {
        text = parseInt(size / (1024 * 1024)) + ' MB'
      }
      return text
    },
  },
  watch: {
    value(newVal) {
      this.val = newVal
      if (newVal && this.status === 0) {
        this.name = newVal.split('/').pop()
        this.status = 6
      }
    },
    'upload.progress'(val) {
      if (val >= 100) {
        this.upload.progress = 100
        clearInterval(this.upload.timer)
      }
    }
  },
  methods: {
    handleEmit() {
      const { val, name } = this
      this.$emit('input', val)
      this.$emit('change', val, name)
    },
    handleClick() {
      this.$refs.uploadRefs.$refs['upload-inner'].handleClick()
    },
    // 重置
    handleReset(event) {
      event && event.stopPropagation()
      this.status = 0
      this.name = ''
      this.size = 0
      this.analyze_id = ''
      this.upload = { ...defaultUpload }
      this.analyze = { ...defaultAnalyze }
      this.val = ''
      this.handleEmit()
    },
    // 上传校验
    handleBeforeUpload(file) {
      const { name, size } = file
      const isLimit50M = size / 1024 / 1024 < 50
      if (isLimit50M) {
        this.status = 1
        this.upload.progress = 0
        this.analyze.progress = 0
        this.size = size
        this.name = name
      } else {
        this.$message.error('文件大小不能超过 50MB，请重新上传！')
      }
      return isLimit50M
    },
    // 上传中
    handleOnProgress(event, file) {
      // 神策数据埋点
      sensorsTrack('PCB1_quotation_UploadGerber_click', { entrance: '新版PCB计价：上传文件' })

      const toFixed_1 = val => Number(val.toFixed(1))
      // 上传进度条处理
      const percent = toFixed_1(event.percent * 0.95)
      this.upload.progress = Number(percent)
      if (event.percent == 100) {
        this.upload.timer = setInterval(() => {
          if (this.upload.progress < 100) {
            const random = toFixed_1(Math.floor(Math.random() * 10) * 0.1)
            this.upload.progress = toFixed_1(this.upload.progress + random)
          }
        }, 300)
      }
    },
    // 上传成功
    handleOnSuccess(res) {
      const { status, url, name, analyze_id, msg } = res
      if (status) {
        this.upload.progress = 100
        const timer = setTimeout(() => {
          this.analyze_id = analyze_id
          this.val = url
          this.handleEmit()
          if (this.type === 'smt_3') {
            // 不需要解析
            this.status = 6
          } else {
            this.status = 2
            this.startAnalyze()

            // 需要解析3D
            this.isShowAnalyze3D = this.needAnalyeze3D
          }
          clearTimeout(timer)
        }, 400)
      } else {
        this.upload.progress = 0
        this.analyze.progress = 0
        this.status = 3
        this.errorMsg = msg
      }
    },
    // 上传失败
    handleOnError() {
      this.status = 3
      this.handleReset()
    },
    
    handleAnalyzeChange(key) {
      this.isShowAnalyze3D = true
      this.$nextTick(() => {
        this.analyze.currentKey = key
        this.startAnalyze(key)
      })
    },
    // 文件解析
    startAnalyze(key) {
      this.startCountdown()
      this.startProgress()
      this.analyze.analyzeing = true
      this.analyze.analyzeTimer = setInterval(() => {
        const params = { analyze_id: this.analyze_id }
        fileAnalyzeResult(params, key)
          .then(res => {
            if (!res) return
            const { code, data = {} } = res
            if (code == 200) {
              const { items = [], key, result } = data
              this.analyze.currentKey = key
              this.analyze.list = items
              // 解析失败
              if (items.every(i => i.status != 1 && i.status !== 0)) {
                this.status = 5
                this.endAnalyze(5)
                return
              }

              this.analyze.result = result
              // 数据解析成功
              if (result) {
                // 如果解析出来是3层板，则设为4层
                if (result.blayer == 3) {
                  result.blayer = 4
                }
                result.file_type = result.fileType
              }

              this.endAnalyze(4)
              this.$emit('analyzeSuccess', result || { file_type: -1 })
            }
            if (code < 105) {
              this.analyzeFailed()
            }
          })
          .catch(err => {})
      }, 1500)
    },
    // 解析完成：4-解析成功 5-解析失败
    endAnalyze(status) {
      clearInterval(this.analyze.analyzeTimer)
      this.endProgress()
      this.endCountdown()
      this.analyze.analyzeing = false
      const timer = setTimeout(() => {
        this.status = status
        clearTimeout(timer)
      }, 500)
    },
    // 解析失败
    analyzeFailed() {
      this.endAnalyze(5)
      this.$emit('analyzeSuccess', { file_type: -1 })
    },
    // 开始倒计时
    startCountdown() {
      this.analyze.countdown = 60
      this.analyze.countdownTimer = setInterval(() => {
        this.analyze.countdown--
        // 解析时间超过60秒没结果则按解析失败处理
        if (this.analyze.countdown <= 0) {
          this.analyzeFailed()
        }
      }, 1000)
    },
    // 结束倒计时
    endCountdown() {
      clearInterval(this.analyze.countdownTimer)
      this.analyze.countdownTimer = null
    },
    // 解析进度条进度 开始
    startProgress() {
      // 定时器间隔
      const frequency = 100
      let time = 0
      this.analyze.progress = 0
      this.analyze.timer = setInterval(() => {
        time += frequency
        if (this.analyze.progress >= 1) {
          this.endProgress()
          return
        }
        this.analyze.progress = 1 - Math.exp((-1 * time) / this.analyze.timeConstant)
      }, frequency)
    },
    // 解析进度条进度 结束
    endProgress() {
      this.analyze.progress = 1
      clearInterval(this.analyze.timer)
      this.analyze.timer = null
    },
    // 3D解析完成
    handleAnalyze3DComplete(isSuccess) {
      // 解析成功后，不再显示3D解析组件
      this.isShowAnalyze3D = !isSuccess
      this.$emit('analyze3DComplete', {
        isShow: isSuccess,
        loading: false,
        analyze_id: this.analyze_id,
        key: this.analyze.currentKey
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pcbfile-upload ::v-deep {
  .el-upload {
    display: block;
    color: $--color-text-primary;
    &:focus {
      color: $--color-text-primary;
    }
    .el-upload-dragger {
      padding: 18px 22px;
      width: 630px;
      height: auto;
      line-height: 1;
      border-radius: 5px;
      border-color: $--form-color-base;
      background-color: $--input-background-color;
      &:hover {
        border-color: $--form-color-base;
      }
    }
  }

  &.pcbfile-upload--fail {
    .el-upload-dragger {
      border-color: $--color-warning;
      background-color: #FDF6EC;
    }
  }

  .pcbfile-upload__success__name {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 12px;
    border-radius: 3px;
    border: 1px dashed $--color-success;
    background: #ffffff;
    &.active {
      color: $--color-success;
    }
    >img {
      width: 20px;
    }
    >p {
      flex: 1;
      margin-left: 10px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    >i {
      font-size: 16px;
      color: $--color-success;
    }
  }

  .pcbfile-upload__analyzeing {
    margin: 16px 0 10px;
    font-weight: 700;
    text-align: left;
    >span {
      margin-left: 10px;
      font-weight: 400;
      color: $--color-text-secondary;
    }
  }

  .pcbfile-upload__button.el-button--text {
    color: $--color-text-secondary;
    &:hover {
      background-color: #fff;
    }
  }

  .pcbfile-upload__notStarted {
    >img {
      width: 50px;
      height: 50px;
    }
    >div {
      margin: 4px 0 8px;
      font-size: 13px;
      color: $--color-text-primary;
      >em {
        color: $--color-warning;
        font-style: normal;
      }
      >i {
        color: $--color-danger;
      }
    }
    >p {
      font-size: 12px;
      color: $--color-text-placeholder;
    }
  }
  .pcbfile-upload__uploading {
    text-align: left;
    .pcbfile-upload__uploading__name {
      display: flex;
      color: $--color-text-primary;
      >p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      >span {
        flex-shrink: 0;
        margin-left: 4px;
        color: $--color-text-disabled;
      }
    }
    >p {
      margin: 25px 0 10px;
      font-weight: 700;
    }
    .el-progress {
      margin-bottom: 27px;
    }
  }
  .pcbfile-upload__success {
    text-align: left;
  }
  .pcbfile-upload__fail {
    .pcbfile-upload__fail__text {
      color: $--color-danger;
      >i {
        font-size: 20px;
      }
      >p {
        margin-top: 5px;
        line-height: 20px;
        font-size: 15px;
        font-weight: 700;
      }
    }
    >p {
      margin: 5px 0 10px;
      line-height: 16px;
      font-size: 12px;
      color: $--color-text-disabled;
    }
    .pcbfile-upload__fail__btns {
      position: relative;
      .el-button--default {
        width: 165px;
        color: $--form-color-base;
        border-color: $--form-color-base;
        &:hover {
          background-color: $--input-background-color;
        }
      }
      .el-button--text {
        position: absolute;
      }
    }
  }
  .pcbfile-upload__analyze {
    .pcbfile-upload__success__name >p {
      font-weight: 700;
    }
    .pcbfile-upload__analyze__text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 7px 12px;
      border-radius: $--border-radius-base;
      background-color: #fff;
      >p {
        >i {
          font-size: 15px;
          color: $--color-success;
        }
        >em {
          margin: 0 10px 0 12px;
          font-style: normal;
          font-weight: 700;
          color: $--color-success;
        }
        >span {
          font-size: 12px;
          color: $--color-text-placeholder;
        }
      }
      >a {
        font-size: 12px;
        color: $--color-text-secondary;
        &:hover {
          color: $--link-color;
          text-decoration: underline;
        }
      }
      &.fail {
        background-color: #FDF6EC;
        >p {
          >i,
          >em {
            color: $--color-warning;
          }
        }
      }
    }
    .pcbfile-upload__analyze__btns {
      margin-top: 10px;
    }
  }
}

// 有3D解析
.pcbfile-upload__analyze3D--4,
.pcbfile-upload__analyze3D--5 {
  position: relative;
  .pcbfile-upload__analyze {
    .pcbfile-upload__analyze__btns {
      margin-top: 38px;
    }
  }
  .pcbfile-upload__analyze3D {
    position: absolute;
    left: 0;
    bottom: 56px;
    width: 100%;
  }
}
.pcbfile-upload__analyze3D--4.analyzeing {
  .pcbfile-upload__analyze {
    margin-bottom: 36px;
  }
  .pcbfile-upload__analyze3D {
    bottom: 20px;
  }
}

.pcbfile-tips {
  margin-top: 2px;
  line-height: 24px;
  font-size: 12px;
  text-align: center;
  color: $--color-text-disabled;
  border-radius: 3px;
  background: $--background-disabled;
}
</style>