<!-- 贴片料 -->
<template>
  <BaseFormItem
    class="patch"
    :label="fields.label"
  >
    <!-- 单/双面 -->
    <BaseRadioGroup v-model="val.single_or_double_technique" @change="() => handleEmit('single_or_double_technique')">
      <el-radio-button
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
      >
        {{ item.label }}
      </el-radio-button>
    </BaseRadioGroup>
    <i class="el-icon-minus patch__separator" />
    <!-- 贴片物料种类 -->
    <BaseInput 
      class="patch__input required"
      v-model="val.patch_material_type" 
      width="140px"
      placeholder="请输入"
      @input="val => handleEmit('patch_material_type')"
      @change="val => handleChange(val, 'patch_material_type')"
    >
      <div slot="prepend">
        物料
        <BaseTipPopover content="贴片物料种类" />
      </div>
      <template slot="append">种</template>
    </BaseInput>
    <i class="el-icon-minus patch__separator" />
    <!-- 单片贴片点数 -->
    <BaseInput 
      class="patch__input"
      v-model="val.patch_pad_number" 
      width="166px"
      placeholder="请输入"
      @input="val => handleEmit('patch_pad_number')"
      @change="val => handleChange(val, 'patch_pad_number')"
    >
      <div slot="prepend">
        单片点数
        <BaseTipPopover content="单片贴片点数" />
      </div>
      <template slot="append">个</template>
    </BaseInput>
  </BaseFormItem>
</template>

<script>
import { getSMTFields } from '@@/utils/getFields'
import { isPositiveInteger } from '@@/utils/utils'

export default {
  props: {
    // 单/双面
    single_or_double_technique: {
      type: Number,
      default: 1
    },
    // 贴片物料种类
    patch_material_type: {
      type: [Number, String],
      default: ''
    },
    // 单片贴片点数
    patch_pad_number: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      val: {
        single_or_double_technique: this.single_or_double_technique,
        patch_material_type: this.patch_material_type,
        patch_pad_number: this.patch_pad_number
      }
    }
  },
  computed: {
    fields() {
      return getSMTFields('patch')
    }
  },
  watch: {
    single_or_double_technique(newVal) {
      this.val.single_or_double_technique = newVal
    },
    patch_material_type(newVal) {
      this.val.patch_material_type = newVal
    },
    patch_pad_number(newVal) {
      this.val.patch_pad_number = newVal
    },
  },
  methods: {
    handleEmit(key) {
      this.$emit(`update:${key}`, this.val[key])
    },
    handleChange(val, key) {
      this.val[key] = isPositiveInteger(val) ? val : ''
      this.handleEmit(key)
      this.handleEmitChange()
    },
    handleEmitChange() {
      this.$emit('change', { ...this.val })
    },
  }
}
</script>

<style lang="scss" scoped>
.patch {
  .patch__separator {
    margin: 0 5px;
  }
  .patch__input ::v-deep {
    .el-input-group__prepend {
      padding: 0 5px;
      >div {
        display: inline-block;
      }
    }
    &.required .el-input-group__prepend  {
      &::before {
        content: '*';
        display: inline-block;
        margin-right: 4px;
        color: $--color-danger;
      }
    }
    .el-input-group__append {
      padding: 0 5px;
      background-color: transparent;
      transition: all .3s cubic-bezier(.645, .045, .355, 1);
    }
    .el-input__inner {
      padding: 0 5px;
      border-right: 0;
      &:hover + .el-input-group__append {
        border-color: $--input-border-color-hover;
      }
      &:focus + .el-input-group__append {
        border-color: $--input-border-color-focus !important;
      }
    }
  }
}
</style>