<!-- PCB订单列表 -->
<template>
  <div class="pcb-list">
    <template v-if="!isSelected">
      <!-- 搜索框 -->
      <div class="pcb-list__header">
        <p class="title">请选择PCB订单：</p>
        <BaseInput v-model="params.keyword" placeholder="PCB文件名、订单编号" width="250px" prefix-icon="el-icon-search" >
          <BaseTextButton slot="append" @click.native="handlePageNumChange(1)">搜索</BaseTextButton>
        </BaseInput>
      </div>
      <!-- 列表 -->
      <RadioTable
        v-model="val"
        radioKey="order_id"
        borderStyle="dashed"
        radioLabel="#"
        :loading="loading"
        :columns="columns"
        :tableData="list"
        @change="handleChange"
      />
      <!-- 按钮 -->
      <div class="pcb-list__footer">
        <BaseButton
          class="pcb-list__footer__arrow"
          icon="el-icon-arrow-left"
          :disabled="!showClickPrevPage"
          @click="handlePageNumChange(params.page_num - 1)"
        ></BaseButton>
        <BaseButton
          class="pcb-list__footer__arrow"
          icon="el-icon-arrow-right"
          :disabled="!showClickNextPage"
          @click="handlePageNumChange(params.page_num + 1)"
        ></BaseButton>
        <BaseButton round @click="handleConfirm">确认</BaseButton>
      </div>
    </template>
    <!-- 已选 -->
    <div class="pcb-list__selected" v-else>
      <span class="pcb-list__selected__text">
        已选：{{ currentItem.pcbfile_name }} ( {{ currentItem.order_id }}，<BaseLink :href="detailUrl" target="_blank">查看详情</BaseLink>)
      </span>
      <BaseTextButton @click="isSelected = false">修改</BaseTextButton>
    </div>
  </div>
</template>

<script>
import RadioTable from '@@/components/radioTable/index'

import CONFIG from '@@/config'
import { isSmtSite } from '@@/utils/is'
import { getPcbOrdersToBind } from '@@/api/pcba'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    RadioTable
  },
  data() {
    return {
      loading: false,
      val: this.item.order_id,
      columns: [
        { label: 'PCB文件名', prop: 'pcbfile_name', showOverflowTooltip: true },
        { label: '订单编号', prop: 'order_id', width: 100 },
        { label: '下单时间', prop: 'timeAfterConversion', width: 160 },
        { label: '状态', prop: 'status_desc', width: 100 }
      ],
      params: {
        keyword: '', // 文件名称、订单号
        page_num: 1, // 第几页
        page_size: 5, // 每页多少条
      },
      list: [],
      total: 0,
      currentItem: this.item,
      isSelected: !!this.item.order_id, // 是否已选
    }
  },
  computed: {
    maxPage() {
      return Math.ceil(this.total / this.params.page_size)
    },
    showClickPrevPage() {
      return this.maxPage > 0 && this.params.page_num != 1
    },
    showClickNextPage() {
      return this.maxPage > 0 && this.params.page_num < this.maxPage
    },
    // 查看详情url
    detailUrl() {
      const order_id = this.currentItem.order_id
      if (isSmtSite) {
        return `${CONFIG.HQCHIP_HOST}/mycenter/order/pcb/detail?id=${order_id}`
      } else {
        return `/mycenter/order/pcb/detail?id=${order_id}`
      }
    }
  },
  watch: {
    item: {
      deep: true,
      handler: function (item) {
        item = item || {}
        this.val = item.order_id || ''
        this.currentItem = {...item}
        this.isSelected = !!item.order_id
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      getPcbOrdersToBind(this.params)
        .then(res => {
          if (!res) return
          const { retCode, result, retMsg } = res
          if (retCode === 0) {
            this.list = (result || {}).list || []
            this.total = (result || {}).pageInfo.total
          } else {
            this.$message.error(retMsg || '未知错误，请稍后重试')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handlePageNumChange(num) {
      this.params.page_num = num
      this.getList()
    },
    handleChange(item) {
      this.currentItem = item
    },
    handleConfirm() {
      const currentItem = this.currentItem
      if (!currentItem.order_id) {
        this.$message.warning('请选择PCB订单')
        return
      }
      this.isSelected = true
      this.$emit('change', { ...currentItem })
    }
  }
}
</script>

<style lang="scss" scoped>
.pcb-list {
  padding: 10px;
  width: 600px;
  border-radius: 5px;
  // border: 1px dashed $--form-color-base;
  background-color: $--background-hover;
  .pcb-list__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    .title {
      font-size: 13px;
      color: $--color-text-primary;
    }
    ::v-deep {
      .el-input {
        position: relative;
      }
      .el-input__inner {
        border-radius: 14px;
        height: 28px;
      }
      .el-input-group__append {
        position: absolute;
        border: 0;
        padding: 0;
        top: 4px;
        right: 3px;
        height: 20px;
        width: 45px;
        line-height: 20px;
        border-radius: 11px;
        font-size: 12px;
        background: #f2f2f2;
        text-align: center;
        .el-button {
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .pcb-list__footer {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    ::v-deep {
      .base-button.el-button {
        margin-left: 10px;
        &.pcb-list__footer__arrow {
          padding: 7px;
          margin-left: 5px;
        }
      }
      .base-button.el-button.pcb-list__footer__arrow {
        color: $--input-border-color-hover;
        border-color: $--button-text-hover-border-color;
        background-color: $--button-color;
        &:focus, &:hover {
          color: $--input-border-color-hover;
          border-color: $--button-text-hover-border-color;
          background-color: $--button-color;
        }
        &.is-disabled {
          background-color: $--input-border-color-disabled;
        }
      }
    }
  }

  // 已选
  .pcb-list__selected {
    padding-left: 20px;
    color: $--color-text-primary;
    ::v-deep {
      .base-text-button.el-button {
        margin-left: 15px;
        color: $--button-text-hover-color;
        border-color: $--button-text-hover-border-color;
        background-color: $--button-color;
        &:focus, &:hover {
          color: $--button-text-hover-backgrounp-color;
          border-color: $--button-text-border-color;
          background-color: $--button-text-hover-color;
        }
      }
    }
  }
}
</style>