<!-- BOM物料 -->
<template>
  <BaseFormItem
    class="buy_bom"
    :label="fields.label"
    :labelTips="fields.labelTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio
        border
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
        class="buy_bom__radio"
      >
        {{ item.label }}
        <span v-if="item.desc" class="buy_bom__desc">
          {{ item.desc }}
        </span>
        <span v-if="item.badge" class="buy_bom__badge">
          {{ item.badge }}
        </span>
      </el-radio>
    </BaseRadioGroup>
    <!-- BOM文件、坐标文件上传 -->
    <div v-show="[1, 2, 4].includes(val)" class="buy_bom__upload">
      <i :class="['buy_bom__upload__linker', `linker_${val}`]"></i>
      <p class="buy_bom__upload__tips">
        <template v-if="val == 4">
          适合工程师等专业用户
        </template>
        <template v-else-if="val == 1">
          适合采购、新手等用户；如需<span>自供部分物料</span>，请在BOM文件内备注
        </template>
        <template v-else-if="val == 2">
          请在PCBA订单付款后及时寄出物料
        </template>
      </p>
      <div class="buy_bom__upload__list">
        <div class="buy_bom__upload__item">
          <p>
            BOM文件
            <BaseTextButton 
              v-if="val === 4 && +bomId"
              class="buy_bom__upload__item__btn" 
              @click="$refs.bomItemDialogRef.open(bomId)"
            >
              查看匹配详情
            </BaseTextButton>
            <span v-if="!bomId" class="buy_bom__upload__item__alert">
              请提供最新版本的生产BOM
            </span>
          </p>
          <UploadAndAnalyze 
            ref="bomUploadRef"
            type="bom" 
            :fileUrl="bomFile.url"
            :fileName="bomFile.name"
            @change="handleBomFileChange"
            @beforeLogin="() => $emit('beforeLogin')"
          />
          <a @click="downloadTemplateUrl(0)">BOM模板下载</a>
        </div>
        <div class="buy_bom__upload__item">
          <p>
            坐标文件
            <span v-if="val === 4" class="buy_bom__upload__item__alert">
              自助匹配选型不支持上传压缩文件
            </span>
          </p>
          <UploadAndAnalyze 
            ref="smtUpload1"
            key="smtUpload1"
            :type="val === 4 ? 'one_stop' : 'smt'"
            :fileUrl="smtFile.url"
            :fileName="smtFile.name"
            :accept="canUploadZip ? '.zip,.rar,.xls,.xlsx,.csv' : undefined"
            :tips="canUploadZip ? '.zip、.rar、.xls、.xlsx、.csv格式 (50M以内)' : undefined"
            @change="handleSmtFileChange"
            @beforeLogin="() => $emit('beforeLogin')"
          />
          <a @click="downloadTemplateUrl(1)">坐标文件模板下载</a>
        </div>
      </div>
    </div>
    <!-- BOM订单列表 -->
    <div v-show="val === 3" class="buy_bom__list">
      <i class="buy_bom__list__linker"></i>
      <BomList 
        v-if="val === 3" 
        :item="currentItem" 
        @change="handleItemChange" 
      />
      <div class="buy_bom__list__upload">
        <UploadAndAnalyze 
          ref="smtUpload2"
          key="smtUpload2"
          type="smt"
          labelTips="上传坐标文件:"
          :fileUrl="smtFile.url"
          :fileName="smtFile.name"
          :accept="canUploadZip ? '.zip,.rar,.xls,.xlsx,.csv' : undefined"
          :tips="canUploadZip ? '.zip、.rar、.xls、.xlsx、.csv格式 (50M以内)' : undefined"
          @change="handleSmtFileChange"
          @beforeLogin="() => $emit('beforeLogin')"
        />
      </div>
    </div>
    <!-- BOM查看明细弹框 -->
    <BomItemDialog ref="bomItemDialogRef" />
  </BaseFormItem>
</template>

<script>
import BomList from '@@/components/bomList/index'
import UploadAndAnalyze from '../components/uploadAndAnalyze'
// BOM查看明细弹框
import BomItemDialog from '@@/views/submit/components/bomItemDialog'

import { getSMTFields } from '@@/utils/getFields'
import { bomSmtTemplateDownload } from '@@/api/pcba'

export default {
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    item: {
      type: Object,
      default: () => ({})
    },
    bomFile: {
      type: Object,
      default: () => ({})
    },
    smtFile: {
      type: Object,
      default: () => ({})
    },
    // BOM选型id
    bomId: {
      type: [String, Number],
      default: ''
    }
  },
  components: {
    BomList,
    UploadAndAnalyze,
    BomItemDialog
  },
  data() {
    return {
      val: this.value,
      currentItem: {},
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getSMTFields('buy_bom')
    },
    // 非 自助匹配选型 时，坐标文件可以上次压缩文件
    canUploadZip() {
      return this.val !== 4
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    item(item) {
      this.currentItem = item
    }
  },
  methods: {
    // BOM、SMT模板下载
    downloadTemplateUrl(downloadType) {
      bomSmtTemplateDownload({ downloadType })
        .then(res => {
          if (!res) return
          const { retCode, result } = res
          if (retCode === 0) {
            window.open(result)
          }
        })
    },
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.handleEmitChange()
      this.handleResetUploadErrorStatus()
    },
    handleEmitChange() {
      const { val, currentItem } = this
      this.$emit('change', val, val === 3 && currentItem.bomSn ? currentItem : {})
      this.isShowError(false)
    },
    handleItemChange(item) {
      this.currentItem = item
      this.handleEmitChange()
    },
    // BOM文件上传成功
    handleBomFileChange(data) {
      this.$emit('bomFileChange', data)
      this.isShowError(false)
    },
    // 坐标文件上传成功
    handleSmtFileChange(data) {
      this.$emit('smtFileChange', data)
      this.isShowError(false)
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    },
    // 重置上传失败状态
    handleResetUploadErrorStatus() {
      this.$refs.bomUploadRef && this.$refs.bomUploadRef.handleErrorReset()
      this.$refs.smtUpload1 && this.$refs.smtUpload1.handleErrorReset()
      this.$refs.smtUpload2 && this.$refs.smtUpload2.handleErrorReset()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@@/styles/mixin.scss';

.buy_bom {
  .buy_bom__radio {
    position: relative;
  }
  .buy_bom__desc {
    color: $--color-text-placeholder;
  }
  .buy_bom__badge {
    position: absolute;
    top: -14px;
    right: -2px;
    display: block;
    width: 24px;
    height: 18px;
    font-size: 12px;
    text-align: center;
    line-height: 18px;
    border-radius: 10px;
    border: 1px solid #DE533C;
    color: #DE533C;
    background-color: #fdf2f0;
  }
  .buy_bom__upload {
    position: relative;
    box-sizing: border-box;
    width: 630px;
    margin-top: 5px;
    padding: 12px 18px;
    border-radius: $--border-radius-base;
    // border: 1px dashed $--form-color-base;
    background-color: $--background-base;
    .buy_bom__upload__linker {
      @include moduleLinkerMixin(15px, vertical, dark);
      position: absolute;
      top: -10px;
      &.linker_1 {
        left: 126px;
      }
      &.linker_2 {
        left: 462px;
      }
      &.linker_4 {
        left: 6px;
      }
    }
    .buy_bom__upload__tips {
      line-height: 1.5;
      font-size: 12px;
      color: $--color-text-placeholder;
      >span {
        color: $--color-warning;
      }
    }
    .buy_bom__upload__list {
      display: flex;
      justify-content: space-between;
    }
    .buy_bom__upload__item {
      width: calc(50% - 8px);
      line-height: 1;
      >p {
        margin: 12px 0 8px;
        font-weight: 700;
        &::before {
          content: '* ';
          color: $--color-danger;
        }
        .buy_bom__upload__item__btn {
          padding: 0;
        }
        .buy_bom__upload__item__alert {
          margin-left: 10px;
          padding: 2px 8px;
          font-size: 12px;
          font-weight: 400;
          color: #E6A23C;
          border-radius: 4px;
          background-color: #FDF1D8;
        }
      }
      >a {
        display: block;
        margin-top: 8px;
        text-align: center;
        color: $--color-text-disabled;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .buy_bom__list {
    position: relative;
    margin-top: 5px;
    .buy_bom__list__linker {
      @include moduleLinkerMixin(15px, vertical, dark);
      position: absolute;
      top: -10px;
      left: 246px;
    }
    .buy_bom__list__upload {
      margin-top: 10px;
    }
  }
}
</style>