<!-- PCB 生产周期 -->
<template>
  <div class="choose-deltime">
    <div class="choose-deltime__header">
      <p class="quote-container__right__title">生产周期</p>
      <BaseLink href="https://www.hqpcb.com/youpin" target="_blank">
        出货标准
      </BaseLink>
    </div>
    <!-- 油墨 冷偏工艺提示 -->
    <BaseTipPopover v-if="colorTips" class="choose-deltime__colorTips" :content="colorTips" />
    <!-- PCB 生产周期选项 -->
    <PcbDeltimeTable :value="value" :options="options" :colorTips="colorTips" @change="handleChange" />
  </div>
</template>

<script>
import PcbDeltimeTable from './pcbDeltimeTable'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => ([])
    },
    colorTips: {
      type: String,
      default: ''
    }
  },
  components: {
    PcbDeltimeTable
  },
  watch: {
    colorTips(newVal) {
      this.colorTips = newVal
    }
  },
  methods: {
    handleChange(val) {
      this.$emit('change', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.choose-deltime {
  position: relative;
  .choose-deltime__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .choose-deltime__colorTips {
    position: absolute;
    top: 47px;
    left: 180px;
    z-index: 9999;
  }
}
</style>