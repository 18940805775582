<!-- 拼版规则 示例 -->
<template>
  <el-popover 
    popper-class="layout-example"
    placement="right" 
    width="400" 
    trigger="hover"
  >
    <div class="layout-example__content">
      <p class="layout-example__title">拼版示例</p>
      <el-descriptions
        border
        size="mini" 
        class="layout-example__table" 
        labelClassName="layout-example__table__label" 
        :column="1" 
      >
        <!-- 拼版后大小 -->
        <el-descriptions-item label="拼版后大小">
          <span class="layout-example__table__size">{{ data.setlength || '0.00' }} * {{ data.setwidth || '0.00' }}</span>
          <i> cm</i>
        </el-descriptions-item>
        <!-- 拼版后数量 -->
        <el-descriptions-item label="拼版后数量">
          {{ data.bcount || 0 }}
          <i>连片</i>
          <span>
            ({{data.pcsNum || 0}}<i>单片</i>)
          </span>
        </el-descriptions-item>
        <!-- 拼版后面积 -->
        <el-descriptions-item label="拼版后面积">
          {{ ((data.m || 0) / 10000).toFixed(4) }} <i>㎡</i>
        </el-descriptions-item>
      </el-descriptions>

      <div class="layout-example__graphical">
        <p>连片示意图：↓ (仅供参考)</p>
        <div class="graphical">
          <div class="graphical__x">
            <span>{{ data.setlength || 0 }} cm</span>
          </div>
          <div class="graphical__y">
            <span>{{ data.setwidth || 0 }} cm</span>
          </div>

          <div v-if="data.layouty && data.layoutx" :class="['graphical__content', data.sidedirection]">
            <div v-for="i in Number(data.layouty)" class="graphical__content__tr" :style="{ height: `${100 / Number(data.layouty)}%` }">
              <div v-for="i in Number(data.layoutx)" class="graphical__content__td" :style="{ width: `${100 / Number(data.layoutx)}%` }">
                {{ data.length }}
                <span>x</span>
                {{ data.width }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BaseTextButton slot="reference">示例</BaseTextButton>
  </el-popover>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-example {
  .layout-example__title {
    margin-top: 10px;
    font-weight: 700;
  }

  .layout-example__table {
    margin-top: 16px;
    & ::v-deep {
      .el-descriptions__body {
        color: $--color-text-primary;
      }
      .layout-example__table__label {
        width: 100px;
        color: $--color-text-placeholder;
        background-color: #fff;
      }

      .layout-example__table__size {
        color: $--color-primary;
      }
    }
  }

  .layout-example__graphical {
    margin-top: 20px;
    >p {
      margin-bottom: 16px;
      text-align: center;
      font-size: 12px;
      color: $--color-text-placeholder;
    }
  }

  .graphical {
    position: relative;
    width: 400px;
    height: 400px;
    .graphical__x {
      position: relative;
      width: 370px;
      border-top: 1px solid $--border-color-base;
      >span {
        position: absolute;
        top: -9px;
        left: 50%;
        transform: translateX(-50%);
        padding: 0 10px;
        line-height: 18px;
        font-size: 12px;
        color: $--color-text-placeholder;
        background-color: #fff;
      }
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: -9px;
        display: block;
        height: 18px;
        border-left: 1px solid $--border-color-base;
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
    }
    .graphical__y {
      position: absolute;
      top: 18px;
      right: 10px;
      height: 370px;
      border-left: 1px solid $--border-color-base;
      >span {
        position: absolute;
        right: -9px;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px 0;
        line-height: 18px;
        font-size: 12px;
        color: $--color-text-placeholder;
        background-color: #fff;
        writing-mode: vertical-rl
      }
      &::before,
      &::after {
        content: '';
        position: absolute;
        right: -9px;
        display: block;
        width: 18px;
        border-top: 1px solid $--border-color-base;
      }
      &::before {
        top: 0;
      }
      &::after {
        bottom: 0;
      }
    }

    .graphical__content {
      box-sizing: border-box;
      margin-top: 18px;
      width: 370px;
      height: 370px;
      background-color: #89C400;
      $border: 10px solid #0C3A0C;
      &.X {
        border-top: $border;
        border-bottom: $border;
      }
      &.Y {
        border-left: $border;
        border-right: $border;
      }
      &.XY {
        border: $border;
      }
      .graphical__content__tr {
        display: flex;
        border-bottom: 1px dashed #FFF;
        &:last-child {
          border-bottom: 0;
        }
        .graphical__content__td:last-child {
          border-right: 0;
        }
      }
      .graphical__content__td {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        color: #fff;
        border-right: 1px dashed #FFF;
        >span {
          margin: 0 2px;
        }
      }
    }
  }
}
</style>