<!-- 发货时间 -->
<template>
  <div class="delivery-time">
    <p class="quote-container__right__title">
      发货时间
      <BaseTipPopover v-if="isShowPopover">
        <ul class="delivery-time__tips__list">
          <li>
            当天<span>18:00</span>后付款，交期顺延<span>1天</span>
          </li>
          <li>
            当天<span>21:00</span>后EQ处理完成的订单，交期顺延<span>1天</span>
          </li>
        </ul>
      </BaseTipPopover>
    </p>
    <div class="delivery-time__content">
      <template v-if="time">
        <img :src="require('@@/assets/img/icons/icon_deltime.png')" />
        <span>
          今日<span>18:00</span>前付款，预计<span> {{ time }} 22:00</span> 前发货
        </span>
      </template>
      <p v-else>
        还未有发货时间，请填写具体参数
      </p>
    </div>
    <div v-if="!isShowPopover" class="delivery-time__tips">
      <ul class="delivery-time__tips__list">
        <li>
          当天<span>18:00</span>后付款，交期顺延<span>1天</span>
        </li>
        <li>
          当天<span>21:00</span>后EQ处理完成的订单，交期顺延<span>1天</span>
        </li>
        <li>
          周日只接单不安排生产，交期同<span>周一</span>下单一致
        </li>
      </ul>
      <!-- <BaseTextButton @click="isShowPopover = true">不再显示</BaseTextButton> -->
    </div>
    <div v-if="tips" class="delivery-time__tips__other">
      <i class="el-icon-warning"></i>
      {{ tips }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    time: {
      type: String,
      default: ''
    },
    tips: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isShowPopover: false
    }
  }
}
</script>

<style lang="scss" scoped>
.delivery-time {
  padding-bottom: 12px;
  border-bottom: 1px dashed $--border-color-base;
  .delivery-time__content {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px 12px;
    border-radius: $--border-radius-base;
    background-color: $--background-base;
    >img {
      display: block;
      margin-right: 10px;
      width: 22px;
    }
    >span {
      color: $--color-text-primary;
      >span {
        &:first-child {
          font-weight: 700;
        }
        &:last-child {
          color: $--color-primary;
        }
      }
    }
    >p {
      flex: 1;
      text-align: center;
      font-size: 12px;
      color: $--color-text-disabled;
    }
  }
  .delivery-time__tips {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .delivery-time__tips__other {
    margin-top: 10px;
    padding: 7px 10px 6px;
    line-height: 1.5;
    color: $--color-warning;
    border: 1px dashed $--color-warning;
    border-radius: $--border-radius-base;
    background-color: $--tips-background-color;
  }
}
.delivery-time__tips__list {
  padding-left: 20px;
  font-size: 12px;
  color: $--color-text-disabled;
  >li {
    list-style: disc;
    >span {
      color: $--color-danger;
    }
    & + li {
      margin-top: 6px;
    }
  }
}
</style>
