import { debounce } from './utils'
import { MessageBox } from 'element-ui'

let tipsFields = []
// 对提示弹框进行防抖，防止同一时间多次弹框
const debounceAlertTips = debounce((msgs, confirmButtonText) => {
  let tips = `您选择的【${msgs.join("、")}】参数已发生变化，请仔细核对，谢谢！`
  MessageBox.alert(tips, '温馨提示', {
    confirmButtonText: confirmButtonText || '确定',
    lockScroll: false,
    center: true,
    customClass: 'alert-fields-tips',
    closeOnPressEscape: true,
    callback: action => {
      tipsFields = []
    }
  })
}, 500)

export const alertFieldsTips = (field, confirmButtonText) => { 
  tipsFields.push(field)
  if (tipsFields.length > 0) { 
    debounceAlertTips(tipsFields, confirmButtonText)
  }
}
