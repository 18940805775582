<!-- 生产稿确认说明弹框 -->
<template>
  <div>
    <BaseTextButton @click="isShow = true">生产稿确认说明</BaseTextButton>

    <el-dialog 
      append-to-body
      width="600px" 
      title="确认生产稿说明" 
      class="review-file-dialog"
      :close-on-click-modal="false" 
      :lock-scroll="false"
      :visible="isShow"
      @close="isShow = false"
    >
      <div class="review-file-dialog__content">
        <div class="review-file-dialog__content__item">
          <h3>不允许自动确认生产稿</h3>
          <p>· 生产稿会在订单付款后4小时内完成。如遇节假日、非工作时间，则顺延完成</p>
          <p>· 如果您一直未确认，我们将不会自动确认和安排生产</p>
          <p>· 订单交期最终以确认生产稿的时间重新计算</p>
        </div>
        <div class="review-file-dialog__content__item">
          <h3>允许自动确认生产稿</h3>
          <p>· <span>24/48/72小时加急订单：</span>超过<span>1</span>小时后系统自动确认。</p>
          <p>· <span>非加急订单：</span>超过<span>10</span>小时后系统自动确认，订单交期最终以确认生产稿的时间为准重新计算</p>
        </div>
        <div class="review-file-dialog__content__tips">
          <i class="el-icon-warning-outline"></i>选择需要确认生产稿，请<span>24</span>小时内登录官网确认哦~
        </div>
        <div class="review-file-dialog__content__img">
            <img :src="require('@@/assets/img/pcb/hqpcb_gzh.jpg')" class="img">
        </div>
        <div class="review-file-dialog__content__desc">关注并绑定华秋微信公众号</div>
        <div class="review-file-dialog__content__desc">可以及时收到生产稿完成的推送</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false
    }
  }
}
</script>

<style lang="scss" scoped>
.review-file-dialog ::v-deep {
  .el-dialog__header {
    height: 59px;
    line-height: 59px;
    border-bottom: 1px solid $--border-color-base;
    padding: 0 30px;
    text-align: center;
    .el-dialog__title {
      font-size: 16px;
      font-weight: 700;
      color: $--color-text-primary;
    }
    .el-dialog__headerbtn {
      top: 16px;
      right: 18px;
      font-size: 22px;
      font-weight: 700;
      .el-dialog__close {
        color: $--color-text-placeholder;
        &:hover {
          color: $--color-text-primary;
        }
      }
    }
  }
  .el-dialog__body {
    padding: 20px 30px;
  }

  .review-file-dialog__content {
    padding-bottom: 20px;
    .review-file-dialog__content__item {
      margin-bottom: 20px;
      h3 {
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 8px;
      }
      p {
        font-size: 13px;
        font-weight: 400;
        line-height: 30px;
        >span {
          color: $--form-color-base;
        }
      }
    }
    .review-file-dialog__content__tips {
      width: 320px;
      height: 30px;
      line-height: 30px;
      background: $--tips-background-color;
      border: 1px dashed #f4ded4;
      border-radius: 3px;
      font-size: 12px;
      color: $--tips-color;
      padding-left: 10px;
      i {
        color: $--color-warning;
        font-size: 16px;
        vertical-align: middle;
        margin-right: 6px;
        margin-top: -2px;
      }
      span {
        color: $--color-warning;
      }
    }
    .review-file-dialog__content__img {
      text-align: center;
      margin: 40px 0 20px;
      img {
        width: 140px;
      }
    }
    .review-file-dialog__content__desc {
      font-size: 14px;
      color: $--color-text-secondary;
      text-align: center;
    }
  }
}
</style>