<!-- 华秋SMT贴片 是否需要确认生产稿 弹框 -->
<template>
  <el-dialog 
    append-to-body
    top="25vh"
    width="600px" 
    title="是否需要确认生产稿" 
    class="review-file-dialog"
    :close-on-click-modal="false" 
    :lock-scroll="false"
    :visible="isShow"
    @close="handleClose"
  >
    <div class="review-file-dialog__content">
      因您选择需要SMT，我们会帮您添加或修改工艺边或MARK点，为尽量规避因电路板出问题从而导致PCBA元器件的惨重损失，建议你选择需要确认生产稿。
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleEmit(2)">
        需要（不允许自动确认）
      </el-button>
      <el-button type="primary" @click="handleEmit(1)">
        需要（超时系统自动确认）
      </el-button>
      <el-button @click="handleEmit(0)">
        无需
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow: false
    }
  },
  methods: {
    open() {
      this.isShow = true
    },
    handleClose() {
      this.isShow = false
    },
    handleEmit(val) {
      this.$emit('change', val)
      this.handleClose()
    }
  },
}
</script>

<style lang="scss" scoped>
.review-file-dialog ::v-deep {
  .el-dialog__headerbtn:focus .el-dialog__close, 
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: $--form-color-base;
  }
  .el-dialog__body {
    padding: 10px 20px 20px;
    line-height: 24px;
  }

  .el-dialog__footer .el-button {
    &.el-button--primary {
      border-color: $--color-primary;
      background-color: $--color-primary;
    }
    &.el-button--default {
      &:hover {
        color: $--form-color-base;
        border-color: $--form-color-base;
        background-color: $--input-background-color;
      }
    }
  }
}
</style>