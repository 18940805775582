<!-- 登录弹框 -->
<template>
  <el-dialog
    title=""
    width="420px"
    append-to-body
    custom-class="login-dialog"
    :visible.sync="isShow"
    @close="close"
  >
    <div class="login-dialog__container">
      <iframe :src="flowSrc" :style="{ height: iframeHeight }"></iframe>
    </div>
  </el-dialog>
</template>

<script>
import CONFIG from '@@/config'

export default {
  data() {
    return {
      isShow: false,
      flowSrc: null,
      iframeHeight: '100%'
    }
  },
  methods: {
    open(referer) {
      this.isShow = true

      // PCB站点id
      const SSO_SITE_ID = 17
      // 登录后跳转的地址
      referer = referer || encodeURIComponent(location.href)
      this.flowSrc = `${CONFIG.SSO_SITE}/login?referer=${referer}&fromtype=poppcb&siteid=${SSO_SITE_ID}#${encodeURIComponent(location.href)}`
      window.addEventListener('message', this.receiveMessage, false)
    },
    close() {
      this.$emit('close')
      window.removeEventListener('message', this.receiveMessage, false)
    },
    receiveMessage(msg) {
      if (!msg || !msg.data) return

      const data = msg.data
      // 接收到数字时设置iframe的高度
      if (data && !isNaN(Number(data))) {
        this.iframeHeight = `${data}px`
      }
    }
  },
  destroyed() {
    window.removeEventListener('message', this.receiveMessage, false)
  }
}
</script>

<style lang="scss" scoped>
.login-dialog__container {
  min-height: 300px;
  >iframe {
    width: 100%;
    height: 100%;
    margin: 0;
    border: 0;
  }
}
</style>