<!-- 钢网 -->
<template>
  <BaseFormItem
    class="steel_type"
    :label="fields.label"
    :labelTips="fields.labelTips"
  >
    <BaseRadioGroup v-model="val.steel_type" @change="handleEmit">
      <el-radio-button
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
    <BaseCheckbox 
      class="steel_type__checkbox" 
      v-model="val.is_steel_follow_delivery"
      :true-label="1"
      :false-label="0"
      @change="val => $emit('update:is_steel_follow_delivery', val)"
    >
      随货发
    </BaseCheckbox>
  </BaseFormItem>
</template>

<script>
import { getSMTFields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    // 钢网随货发
    is_steel_follow_delivery: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      val: {
        steel_type: this.value,
        is_steel_follow_delivery: this.is_steel_follow_delivery
      },
    }
  },
  computed: {
    fields() {
      return getSMTFields('steel_type')
    }
  },
  watch: {
    value(newVal) {
      this.val.steel_type = newVal
    },
    is_steel_follow_delivery(newVal) {
      this.val.is_steel_follow_delivery = newVal
    }
  },
  methods: {
    handleEmit() {
      const val = this.val.steel_type
      this.$emit('input', val)
      this.$emit('change', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.steel_type {
  .steel_type__checkbox {
    margin-left: 10px;
  }
}
</style>