<!-- PCB板类型 -->
<template>
  <BaseFormItem :label="fields.label">
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getSMTFields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getSMTFields('is_pcb_soft_board')
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
    }
  }
}
</script>

<style lang="scss">
</style>