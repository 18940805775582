<template>
  <!-- SMT信息 -->
  <el-form class="smt-form" size="small" label-width="120px">
    <!-- 生产数量 -->
    <number ref="number"  v-model="smtParams.number" />
    <!-- 产品领域 -->
    <application_sphere ref="application_sphere" v-model="smtParams.application_sphere" />
    <!-- BOM物料 -->
    <buy_bom
      ref="buy_bom"
      v-model="smtParams.buy_bom"
      :item="{
        id: smtParams.order_info.bom_id,
        bomSn: smtParams.order_info.bom_sn,
        fileUrl: smtParams.file_bom,
        bomTitle: smtParams.bom_file_name,
      }"
      @change="handleBomChange"
    />
    <!-- 贴片料 -->
    <patch
      ref="patch"
      :single_or_double_technique.sync="smtParams.single_or_double_technique"
      :patch_material_type.sync="smtParams.patch_material_type"
      :patch_pad_number.sync="smtParams.patch_pad_number"
    />
    <!-- 插件料 -->
    <is_plug
      ref="is_plug" 
      :is_plug.sync="smtParams.is_plug"
      :plug_material_type.sync="smtParams.plug_material_type"
      :plug_number.sync="smtParams.plug_number"
    />
    <!-- 钢网 -->
    <steel_type v-model="smtParams.steel_type" :is_steel_follow_delivery.sync="smtParams.is_steel_follow_delivery" />
    <!-- 刷三防漆 -->
    <need_conformal_coating v-model="smtParams.need_conformal_coating" :is_dodge_solder_joint.sync="smtParams.is_dodge_solder_joint" />
    <!-- 制程要求 -->
    <solder_paste_type ref="solder_paste_type" v-model="smtParams.solder_paste_type" />
    <!-- 特殊工艺 -->
    <process
      :is_assembly_weld.sync="smtParams.is_assembly_weld"
      :is_material_baking.sync="smtParams.is_material_baking"
      :is_assemble.sync="smtParams.is_assemble"
      :need_split.sync="smtParams.need_split"
      :is_layout_cleaning.sync="smtParams.is_layout_cleaning"
      :is_welding_wire.sync="smtParams.is_welding_wire"
      :is_increase_tinning.sync="smtParams.is_increase_tinning"
      :is_test.sync="smtParams.is_test"
      :is_program_burning.sync="smtParams.is_program_burning"
    />
    <!-- X-Ray -->
    <x_ray
      ref="x_ray"
      :x_ray_unit_number.sync="smtParams.x_ray_unit_number"
      :x_ray_number.sync="smtParams.x_ray_number"
    />
    <!-- 样品图确认 -->
    <is_first_confirm ref="is_first_confirm" v-model="smtParams.is_first_confirm" />
    <!-- 包装类型 -->
    <packing_type v-model="smtParams.packing_type" />
    <!-- 工艺备注 -->
    <postscript v-model="smtParams.postscript" />
  </el-form>
</template>

<script>
import { SMT_DEFAULT_FORM } from '@@/utils/defaultForm'

// 自动注册 smt fields 文件夹下的订单字段组件
const requireFields = require.context('../smt/fields', true, /\.vue$/)
let fieldsComponents = {}
requireFields.keys().forEach(fileName => {
  let component = requireFields(fileName)
  fieldsComponents[fileName.replace(/^\.\/(.*)\.\w+$/, '$1')] = component.default
})

export default {
  components: {
    ...fieldsComponents
  },
  inject: {
    smtParams: { default: { ...SMT_DEFAULT_FORM } }
  },
  methods: {
    handleBomChange(buy_bom, item = {}) {
      // 关联BOM订单
      if (buy_bom === 3) {
        const { id, bom_sn, file_name, file_url } = item
        this.smtParams.order_info.bom_id = id
        this.smtParams.order_info.bom_sn = bom_sn
        this.smtParams.file_bom = file_url
        this.smtParams.bom_file_name = file_name
      } else {
        this.smtParams.order_info.bom_id = ''
        this.smtParams.order_info.bom_sn = ''
        this.smtParams.file_bom = ''
        this.smtParams.bom_file_name = ''
      }
    },
    scrollIntoView(key) {
      const ref = {
        patch_material_type: 'patch',
        plug_material_type: 'is_plug'
      }[key] || key
      this.$refs[ref] && this.$refs[ref].$el.scrollIntoView({ behavior: 'smooth' })
    },
    validate(data) {
      const fristItem = data[0]
      this.$message.closeAll()
      this.$message({
        type: 'error',
        offset: 400,
        message: fristItem.tips
      })
      this.scrollIntoView(fristItem.key)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>