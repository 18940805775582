<!-- PCB拼版规则 示例 -->
<template>
  <el-popover 
    popper-class="layout-example"
    placement="right" 
    width="400" 
    trigger="hover"
  >
    <div class="layout-example__graphical">
      <p>拼版示意图：↓ (仅供参考)</p>
      <div class="graphical">
        <div v-if="data.pcb_ban_x && data.pcb_ban_y" class="graphical__content">
          <div 
            v-for="i in Number(data.pcb_ban_y)" 
            class="graphical__content__tr" 
            :style="{ height: `${100 / Number(data.pcb_ban_y)}%` }"
          >
            <div 
              v-for="i in Number(data.pcb_ban_x)" 
              class="graphical__content__td" 
              :style="{ width: `${100 / Number(data.pcb_ban_x)}%` }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <BaseTextButton slot="reference">示例</BaseTextButton>
  </el-popover>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-example__graphical {
  margin-top: 4px;
  >p {
    margin-bottom: 16px;
    text-align: center;
    font-size: 12px;
    color: $--color-text-placeholder;
  }
}

.graphical {
  width: 400px;
  height: 400px;
  .graphical__content {
    box-sizing: border-box;
    width: 400px;
    height: 400px;
    background-color: #89C400;
    .graphical__content__tr {
      display: flex;
      border-bottom: 1px dashed #FFF;
      &:last-child {
        border-bottom: 0;
      }
      .graphical__content__td:last-child {
        border-right: 0;
      }
    }
    .graphical__content__td {
      border-right: 1px dashed #FFF;
    }
  }
}
</style>
