<!-- SMT / SMT + PCB 生产周期 -->
<template>
  <div class="choose-deltime">
    <div class="choose-deltime__header">
      <p class="quote-container__right__title">生产周期</p>
    </div>
    <div class="choose-deltime__content">
      <img :src="require('@@/assets/img/icons/icon_association.png')" />
      <ul>
        <li v-if="showPcb" class="choose-deltime__pcb">
          <span class="choose-deltime__label">PCB</span>
          <el-popover
            class="choose-deltime__pcb__popover"
            width="360"
            placement="bottom-start"
            v-model="isShow"
          >
            <!-- PCB 生产周期选项 -->
            <div class="choose-deltime__pcb__content">
              <!-- 油墨 冷偏工艺提示 -->
              <BaseTipPopover v-if="colorTips" class="choose-deltime__colorTips" :content="colorTips" />
              <PcbDeltimeTable :value="pcbDelTime" :options="options" @change="handleChange" />
            </div>
            <div slot="reference" class="choose-deltime__pcb__reference" :class="{ 'is-focus': isShow }">
              <p v-if="currentItem.name">
                <span>{{ pcbDelTime }}，</span>
                <ColorBlock :color="currentItem.color" showLabel />
                <span>，板厚{{ currentItem.bheight }}</span>
              </p>
              <p v-else class="not-value">请选择</p>
              <i class="el-icon-arrow-up" />
            </div>
          </el-popover>
        </li>
        <li class="choose-deltime__smt">
          <span class="choose-deltime__label">SMT</span>
          <p>{{ smtDelTime || '--' }}</p>
          <!-- SMT生产周期必看 -->
          <SmtDeltimeMustSeeDialog />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ColorBlock from '@@/components/colorBlock/index'
import PcbDeltimeTable from './pcbDeltimeTable'
import SmtDeltimeMustSeeDialog from './smtDeltimeMustSeeDialog'

export default {
  props: {
    smtPrice: {
      type: Object,
      default: () => ({})
    },
    pcbDelTime: {
      type: [String, Number],
      default: ''
    },
    // PCB生产周期选项
    options: {
      type: Array,
      default: []
    },
    showPcb: {
      type: Boolean,
      default: false
    },
    colorTips: {
      type: String,
      default: ''
    }
  },
  components: {
    ColorBlock,
    PcbDeltimeTable,
    SmtDeltimeMustSeeDialog
  },
  computed: {
    currentItem() {
      const { pcbDelTime, options } = this
      return options.find(item => item.name === pcbDelTime) || {}
    },
    // SMT生产周期
    smtDelTime() {
      const { normal_dt, can_free_jiaji } = this.smtPrice || {}
      // const delivery = { 3: '免费12H加急', 4: '免费24H加急' }[+can_free_jiaji]
      // return delivery || (normal_dt ? `${((normal_dt || 0) / 24).toFixed(1)}天` : '--')
      return `${normal_dt || '--'}h`
    }
  },
  data() {
    return {
      isShow: false
    }
  },
  watch: {
    colorTips(newVal) {
      this.colorTips = newVal
    }
  },
  methods: {
    handleChange(val) {
      this.isShow = false
      this.$emit('change', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.choose-deltime {
  .choose-deltime__content {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: $--border-radius-base;
    background-color: $--background-base;
    >img {
      display: block;
      margin-right: 16px;
      width: 22px;
    }
    >ul {
      flex: 1;
      >li {
        display: flex;
        align-items: center;
        .choose-deltime__label {
          margin-right: 13px;
          display: block;
          width: 30px;
          font-weight: 700;
        }
        & + li {
          margin-top: 10px;
        }
      }
    }
    .choose-deltime__pcb {
      .choose-deltime__pcb__popover {
        flex: 1;
      }
      .choose-deltime__pcb__reference {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 0 10px;
        width: 100%;
        height: 26px;
        border: 1px solid $--radio-border-color;
        border-radius: 4px;
        background-color: #fff;
        cursor: pointer;
        transition: border-color .2s cubic-bezier(.645,.045,.355,1);
        &:hover {
          border-color: #aaa;
        }
        &.is-focus {
          border-color: $--form-color-base;
          >i {
            transform: rotateZ(0);
          }
        }
        >i {
          font-size: 14px;
          color: #C0C4CC;
          transform: rotateZ(180deg);
          transition: all .3s;
        }
      }
      .not-value {
        color: $--color-text-placeholder;
      }
    }
    .choose-deltime__smt {
      >p {
        margin-right: 10px;
        width: 88px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        border-radius: $--border-radius-base;
        background-color: #fff;
      }
    }
  }
}
.choose-deltime__pcb__content {
  position: relative;
  .choose-deltime__colorTips {
    position: absolute;
    top: 12px;
    left: 176px;
    z-index: 9999;
  }
}
</style>