<template>
  <el-upload
    drag
    ref="uploadRef"
    name="file"
    class="upload"
    :class="['upload', `upload--${status}`, isSmtNotLogin ? 'upload--notLogin' : '']"
    :accept="accept"
    :action="url"
    :headers="headers"
    :data="{ type }"
    :show-text="false"
    :show-file-list="false"
    :before-upload="handleBeforeUpload"
    :on-progress="handleOnProgress"
    :on-success="handleOnSuccess"
    :on-error="handleOnError"
  >
    <div v-if="isSmtNotLogin" class="upload--notLogin__modal" @click.stop="handleShowLogin"></div>
    <!-- 未开始 -->
    <div v-if="status === 0" class="upload__notStarted">
      <div v-if="labelTips" class="upload__notStarted__labelTips">{{ labelTips }}</div>
      <img src="@@/assets/img/common/upload-img.png"/>
      <div>拖拽文件到此，或 <span ref="uploadHandleRef">点击上传</span></div>
      <p>{{ tips }}</p>
    </div>
    <!-- 上传中 -->
    <div v-if="status === 1" class="upload__uploading" @click.stop>
      <p class="upload__uploading__name">
        {{ name }}
      </p>
      <p class="upload__uploading__text">
        上传中
      </p>
      <el-progress
        color="#15A675"
        text-color="#FFFFFF"
        define-back-color="#EBECEF"
        :stroke-width="18"
        :text-inside="true"
        :percentage="upload.progress"
      ></el-progress>
    </div>
    <!-- 上传成功 解析中 -->
    <div v-if="status === 2" class="upload__success" @click.stop>
      <p class="upload__success__name">
        {{ name }}
      </p>
      <p class="upload__success__text">
        上传成功，解析中
      </p>
      <el-progress
        color="#579DE2"
        text-color="#FFFFFF"
        define-back-color="#EBECEF"
        :stroke-width="18"
        :text-inside="true"
        :percentage="analyze.progress * 100"
        :format="val => `${Math.floor(val)}%`"
      ></el-progress>
    </div>
    <!-- 上传失败 -->
    <div v-if="status === 3" class="upload__fail" @click.stop>
      <p class="upload__fail__text">上传失败</p>
      <p class="upload__fail__tips">{{ errorMsg || '上传失败，请重新上传！' }}</p>
      <BaseTextButton :plain="false" @click="handleClick">
        重新上传
      </BaseTextButton>
    </div>
    <!-- 解析成功 -->
    <div v-if="status === 4" class="upload__analyze" @click.stop>
      <i class="el-icon-success"></i>
      <div class="upload__analyze__name">
        <p>
          {{ name }}
        </p>
      </div>
      <BaseTextButton :plain="false" @click="handleClick">
        重新上传
      </BaseTextButton>
    </div>
    <!-- 解析失败 -->
    <div v-if="status === 5" class="upload__analyze__fail" @click.stop>
      <p class="upload__analyze__fail__text">解析失败</p>
      <BaseTextButton :plain="false" @click="handleClick">
        重新上传
      </BaseTextButton>
    </div>
    <!-- 修改回显 -->
    <div v-if="status === 6" class="upload__analyze" @click.stop>
      <i class="el-icon-success"></i>
      <div class="upload__analyze__name">
        <p>
          {{ name }}
        </p>
      </div>
      <BaseTextButton :plain="false" @click="handleClick">
        重新上传
      </BaseTextButton>
    </div>
  </el-upload>
</template>

<script>
import CONFIG from '@@/config'
import { getToken } from '@@/utils/auth'
import { isLogin, isSmtSite } from '@@/utils/is'

// 上传
const defaultUpload = {
  progress: 0, // 上传进度百分比
  timer: null, // 上传进度百分比定时器
}
// 解析
const defaultAnalyze = {
  analyzeing: false, //解析中
  progress: 0, // 解析进度百分比
  timer: null, // 解析进度百分比定时器
  analyzeTimer: null, // 解析接口定时器
  countdown: 60, // 解析倒计时
  countdownTimer: null, // 倒计时定时器
  timeConstant: 5000, // 进度条变化的快慢，数字越大，变化的越慢
}

export default {
  props: {
    type: {
      type: String,
      default: 'bom'
    },
    fileUrl: {
      type: String,
      default: ''
    },
    fileName: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: '.xls,.xlsx,.csv'
    },
    limitSize: {
      type: Number,
      default: 50
    },
    tips: {
      type: String,
      default: '.xls、.xlsx、.csv格式 (50M以内)'
    },
    labelTips: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      headers: {
        Authorization: getToken()
      },
      status: 0, // 状态： 0-未开始, 1-上传中, 2-上传成功, 3-上传失败, 4-解析成功, 5-解析失败, 6-修改回显
      name: '', // 文件名称
      size: 0, // 单位B
      analyze_id: '', // 上传成功后返回的id
      errorMsg: '', // 上传失败提示
      upload: { ...defaultUpload },
      analyze: { ...defaultAnalyze }
    }
  },
  computed: {
    url() {
      return `${CONFIG.HQPCBA_HOST}/pcba/app/file/fileUpload`
    },
    // 是否SMT站点且未登录
    isSmtNotLogin() {
      return isSmtSite && !isLogin()
    }
  },
  watch: {
    fileName(newVal) {
      if (newVal && this.status === 0) {
        this.name = newVal
        this.status = 6
      }
      if (!newVal) {
        this.name = ''
        this.status = 0
      }
    }
  },
  methods: {
    handleEmit(data) {
      this.$emit('input', data)
      this.$emit('change', data)
    },
    handleClick() {
      this.$refs.uploadRef.$refs['upload-inner'].handleClick()
    },
    // 上传校验
    handleBeforeUpload(file) {
      const { name, size } = file
      const limitSize = this.limitSize
      const isLimit = size / 1024 / 1024 < limitSize
      if (isLimit) {
        this.status = 1
        this.upload.progress = 0
        this.analyze.progress = 0
        this.size = size
        this.name = name
      } else {
        this.$message.error(`文件大小不能超过 ${limitSize}MB，请重新上传！`)
      }
      return isLimit
    },
    // 上传中
    handleOnProgress(event, file) {
      const toFixed_1 = val => Number(val.toFixed(1))
      // 上传进度条处理
      const percent = toFixed_1(event.percent * 0.95)
      this.upload.progress = Number(percent)
      if (event.percent == 100) {
        this.upload.timer = setInterval(() => {
          if (this.upload.progress < 100) {
            const random = toFixed_1(Math.floor(Math.random() * 10) * 0.1)
            this.upload.progress = Math.min(99.99, toFixed_1(this.upload.progress + random))
          }
        }, 300)
      }
    },
    // 上传成功
    handleOnSuccess(res) {
      const { retCode, result, retMsg } = res
      if (retCode === 0) {
        this.upload.progress = 100
        this.status = 4
        this.handleEmit(result)
      } else {
        this.upload.progress = 0
        this.status = 3
        this.errorMsg = retMsg
      }
    },
    // 上传失败
    handleOnError() {
      this.upload.progress = 0
      this.status = 3
    },
    // 重置
    handleReset() {
      this.status = 0
      this.name = ''
      this.size = 0
      this.analyze_id = ''
      this.upload = { ...defaultUpload }
      this.analyze = { ...defaultAnalyze }
      this.handleEmit()
    },
    // 失败状态重置
    handleErrorReset() {
      if (this.status == 3) {
        this.handleReset()
      }
    },
    // 线上登录弹框
    handleShowLogin() {
      if (this.$smtLogin) {
        this.$emit('beforeLogin')
        this.$nextTick(() => {
          this.$smtLogin({ isShow: true })
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@@/styles/mixin.scss';
.upload ::v-deep {
  .el-upload {
    display: block;
    color: $--color-text-primary;
    &:focus {
      color: $--color-text-primary;
    }
    .el-upload-dragger {
      padding: 13px 16px 16px;
      width: 100%;
      height: auto;
      line-height: 1;
      border-radius: 5px;
      border-color: $--form-color-base;
      background-color: $--input-background-color;
    }
  }

  .base-text-button.el-button {
    color: $--color-text-secondary;
    &:hover {
      color: #fff;
    }
  }

  .upload__notStarted {
    >div {
      margin: 7px 0 11px;
      >span {
        color: $--form-color-base;
      }
    }
    >p {
      font-size: 12px;
      color: $--color-text-placeholder;
    }
    .upload__notStarted__labelTips {
      text-align: left;
    }
  }

  .upload__uploading {
    text-align: left;
    font-size: 12px;
    .upload__uploading__name {
      line-height: 16px;
      @include ellipsis;
    }
    .upload__uploading__text {
      margin: 13px 0 8px;
      font-weight: 700;
    }
  }

  .upload__success {
    text-align: left;
    font-size: 12px;
    .upload__success__name {
      line-height: 16px;
      @include ellipsis;
    }
    .upload__success__text {
      margin: 13px 0 8px;
      font-weight: 700;
    }
  }

  .upload__fail {
    .upload__fail__text {
      color: $--color-danger;
    }
    .upload__fail__tips {
      margin: 6px 0 8px;
      font-size: 12px;
      color: $--color-text-disabled;
    }
  }

  .upload__analyze {
    text-align: left;
    >i {
      position: absolute;
      top: -6px;
      right: -6px;
      font-size: 18px;
      color: $--color-success;
    }
    .upload__analyze__name {
      display: flex;
      align-items: center;
      margin-bottom: 7px;
      height: 40px;
      >p {
        line-height: 20px;
        @include ellipsisMultiple(2)
      }
    }
  }

  .upload__analyze__fail {
    .upload__analyze__fail__text {
      margin: 12px 0 14px;
      color: $--color-danger;
    }
  }
}

.upload {
  &.upload--3 ::v-deep,
  &.upload--5 ::v-deep {
    .el-upload-dragger {
      border-color: $--color-danger;
      background-color: #fff;
    }
  }
  &.upload--4 ::v-deep,
  &.upload--6 ::v-deep {
    .el-upload-dragger {
      padding: 8px 16px 13px;
      border-color: $--color-success;
      background-color: #fff;
      overflow: visible;
    }
  }
}

.upload--notLogin {
  position: relative;
  .upload--notLogin__modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>