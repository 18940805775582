<!-- PCB拼版规则 -->
<template>
  <BaseFormItem
    class="pcb_ban_layout"
    :unit="fields.unit"
    :label="fields.label"
    :labelTips="fields.labelTips"
    :fillTips="fillTips"
  >
    <BaseInput
      v-model="val.pcb_ban_x"
      :maxlength="5"
      width="84px"
      placeholder="横片数量"
      @input="val => $emit('update:pcb_ban_x', val)"
      @change="() => handleEmitChange('pcb_ban_x')"
    ></BaseInput>
    <span class="pcb_ban_layout__linker">x</span>
    <BaseInput
      v-model="val.pcb_ban_y"
      :maxlength="5"
      width="84px"
      placeholder="纵片数量"
      @input="val => $emit('update:pcb_ban_y', val)"
      @change="() => handleEmitChange('pcb_ban_y')"
    ></BaseInput>
    <!-- 示例 -->
    <LayoutExample
      slot="other"
      :data="val" 
    />
  </BaseFormItem>
</template>

<script>
import LayoutExample from '../components/layoutExample'

import { getSMTFields } from '@@/utils/getFields'
import { isPositiveInteger } from '@@/utils/utils'

export default {
  props: {
    pcb_ban_x: {
      type: [String, Number],
      default: ''
    },
    pcb_ban_y: {
      type: [String, Number],
      default: ''
    }
  },
  components: {
    LayoutExample
  },
  data() {
    return {
      val: {
        pcb_ban_x: this.pcb_ban_x,
        pcb_ban_y: this.pcb_ban_y
      }
    }
  },
  computed: {
    fields() {
      return getSMTFields('pcb_ban_num')
    },
    // 是否显示未填写样式
    fillTips() {
      const { pcb_ban_x, pcb_ban_y } = this.val
      return !pcb_ban_x && !pcb_ban_y ? '请填写' : ''
    }
  },
  watch: {
    pcb_ban_x(pcb_ban_x) {
      this.val.pcb_ban_x = pcb_ban_x
    },
    pcb_ban_y(pcb_ban_y) {
      this.val.pcb_ban_y = pcb_ban_y
    }
  },
  methods: {
    handleEmit(key) {
      this.$emit(`update:${key}`, this.val[key])
    },
    handleEmitChange(key) {
      let val = parseFloat(this.val[key])
      this.val[key] = isPositiveInteger(val) ? val : ''
      this.handleEmit(key)
      this.$emit('change', { ...this.val })
    }
  }
}
</script>

<style lang="scss" scoped>
.pcb_ban_layout {
  .pcb_ban_layout__linker {
    margin: 0 8px;
    color: #BBBBBB;
  }
}
</style>