<!-- 列表展示 -->
<template>
  <div class="view-list" :style="containerSytle">
    <el-scrollbar v-if="data.length > 0" class="view-list__scrollbar">
        <ul class="view-list__ul">
          <li
            v-for="(item, index) in [...data, ...emptyData]" 
            :key="index" 
            :style="liStyle"
            :class="{
              'last-column': data.length < col ? index === data.length - 1 : (index + 1) % col === 0,
              'last-row': data.length <= col ? true : index >= (Math.floor([...data, ...emptyData].length / col) - 1) * col
            }"
          >
            <span class="view-list__label">
              {{ item.label }}
            </span>
            <span class="view-list__value" :style="valueStyle">
              {{ item.value ? `${item.prefix || prefix}${item.value}` : '' }}
            </span>
          </li>
        </ul>
    </el-scrollbar>
    <div v-else class="view-list__empty">
      <p v-for="text in _emptyText">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 展示的列数
    col: {
      type: Number,
      default: 2
    },
    maxHeight: {
      type: Number,
      default: 120
    },
    // label 宽度
    labelWidth: {
      type: String,
      default: '50%'
    },
    // value 对齐方式
    valueTextAlign: {
      type: String,
      default: 'right'
    },
    // 内容前缀
    prefix: {
      type: String,
      default: ''
    },
    // 要展示的数据：[{ label: xxx, value: xxx }]
    data: {
      type: Array,
      default: () => []
    },
    emptyText: {
      type: String,
      default: '暂无数据'
    }
  },
  computed: {
    _emptyText() {
      let emptyText = this.emptyText.split('\n')
      return emptyText.map(t => t.trim())
    },
    // 需要补空的数量
    emptyData() {
      const { col, data } = this
      const dataLength = data.length
      return new Array((dataLength < col || dataLength % col === 0) ? 0 : col - (dataLength % col)).fill([{}])
    },
    containerSytle() {
      const { maxHeight, col, data } = this
      const ITEM_HEIGHT = 30
      const height = Math.min(maxHeight, Math.ceil(data.length / col) * ITEM_HEIGHT)
      return { height: data.length > 0 ? `${height + 12}px` : 'auto' }
    },
    liStyle() {
      const { col, data } = this
      return { width: `${100 / (data.length < col ? data.length : col)}%` }
    },
    valueStyle() {
      const { valueTextAlign } = this
      return { textAlign: valueTextAlign }
    }
  }
}
</script>

<style lang="scss" scoped>
.view-list {
  box-sizing: border-box;
  padding: 6px 7px;
  font-size: 13px;
  border-radius: $--border-radius-base;
  background-color: $--background-base;
  // height: 120px;
  .view-list__scrollbar {
    height: 100%;
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .view-list__ul {
    display: flex;
    flex-wrap: wrap;
    $border: 1px dashed $--border-color-base;
    padding: 0 8px;
    >li {
      box-sizing: border-box;
      display: flex;
      padding: 6px 3px;
      border-right: $border;
      border-bottom: $border;
      &.last-column {
        border-right: 0;
      }
      &.last-row {
        border-bottom: 0;
      }
      >span {
        box-sizing: border-box;
        display: block;
        &.view-list__label {
          width: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $--color-text-secondary;
        }
        &.view-list__value {
          flex: 1;
          color: $--color-text-primary;
        }
      }
    }
  }
  .view-list__empty {
    padding: 10px 0;
    font-size: 12px;
    text-align: center;
    color: $--color-text-disabled;
    >p + p {
      margin-top: 6px;
    }
  }
}
</style>