<!-- 插件料 -->
<template>
  <BaseFormItem
    class="patch"
    :label="fields.label"
  >
    <!-- 焊接插件料 -->
    <BaseRadioGroup v-model="val.is_plug" @change="handleIsplugChange">
      <el-radio-button
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
        :class="{ patch__radio: item.value === 1 }"
      >
        {{ item.label }}
      </el-radio-button>
    </BaseRadioGroup>
    <template v-if="val.is_plug === 1">
      <i class="el-icon-minus patch__separator" />
      <!-- 插件物料种类 -->
      <BaseInput 
        class="patch__input required"
        v-model="val.plug_material_type" 
        width="140px"
        placeholder="请输入"
        @input="val => handleEmit('plug_material_type')"
        @change="val => handleChange(val, 'plug_material_type')"
      >
        <div slot="prepend">
          物料
          <BaseTipPopover content="插件物料种类" />
        </div>
        <template slot="append">种</template>
      </BaseInput>
      <i class="el-icon-minus patch__separator" />
      <!-- 插件焊盘数 -->
      <BaseInput 
        class="patch__input"
        v-model="val.plug_number" 
        width="166px"
        placeholder="请输入"
        @input="val => handleEmit('plug_number')"
        @change="val => handleChange(val, 'plug_number')"
      >
        <div slot="prepend">
          单片点数
          <BaseTipPopover content="插件焊盘数" />
        </div>
        <template slot="append">个</template>
      </BaseInput>
    </template>
  </BaseFormItem>
</template>

<script>
import { getSMTFields } from '@@/utils/getFields'
import { isPositiveInteger } from '@@/utils/utils'

export default {
  props: {
    // 焊接插件料
    is_plug: {
      type: Number,
      default: 1
    },
    // 插件物料种类
    plug_material_type: {
      type: [Number, String],
      default: ''
    },
    // 插件焊盘数
    plug_number: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      val: {
        is_plug: this.is_plug,
        plug_material_type: this.plug_material_type,
        plug_number: this.plug_number
      }
    }
  },
  computed: {
    fields() {
      return getSMTFields('is_plug')
    }
  },
  watch: {
    is_plug(newVal) {
      this.val.is_plug = newVal
    },
    plug_material_type(newVal) {
      this.val.plug_material_type = newVal
    },
    plug_number(newVal) {
      this.val.plug_number = newVal
    },
  },
  methods: {
    handleEmit(key) {
      this.$emit(`update:${key}`, this.val[key])
    },
    handleChange(val, key) {
      this.val[key] = isPositiveInteger(val) ? val : ''
      this.handleEmit(key)
      this.handleEmitChange()
    },
    handleEmitChange() {
      this.$emit('change', { ...this.val })
    },
    handleIsplugChange(val) {
      if (val === 0) {
        this.val.plug_material_type = ''
        this.val.plug_number = ''
      }
      this.handleEmit('is_plug')
      this.handleEmit('plug_material_type')
      this.handleEmit('plug_number')
      this.handleEmitChange()
    }
  }
}
</script>

<style lang="scss" scoped>
.patch {
  .patch__separator {
    margin: 0 5px;
  }
  .patch__input ::v-deep {
    .el-input-group__prepend {
      padding: 0 5px;
      >div {
        display: inline-block;
      }
    }
    &.required .el-input-group__prepend  {
      &::before {
        content: '*';
        display: inline-block;
        margin-right: 4px;
        color: $--color-danger;
      }
    }
    .el-input-group__append {
      padding: 0 5px;
      background-color: transparent;
      transition: all .3s cubic-bezier(.645, .045, .355, 1);
    }
    .el-input__inner {
      padding: 0 5px;
      border-right: 0;
      &:hover + .el-input-group__append {
        border-color: $--input-border-color-hover;
      }
      &:focus + .el-input-group__append {
        border-color: $--input-border-color-focus !important;
      }
    }
  }
  .patch__radio ::v-deep {
    .el-radio-button__inner {
      padding: 9px 21px 8px;
    }
  }
}
</style>